import { Elm } from "./Main.elm";
import { Auth0Client } from "@auth0/auth0-spa-js";
customElements.define('inner-html', class extends HTMLElement {
    constructor() {
        super();
        const shadowRoot = this.attachShadow({ mode: 'open' });
        this.render = () => {
            shadowRoot.innerHTML = this.content;
        }
    }
    get content() {
        return this.getAttribute('content') || '';
    }

    set content(val) {
        this.shadowRoot.innerHtml = val;
    }

    static get observedAttributes() {
        return ['content'];
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback() {
        this.render();
    }

});


var webAuth = new Auth0Client({
    domain: process.env.AUTH_DOMAIN,
    client_id: process.env.AUTH_CLIENT_ID,
    audience: process.env.AUTH_AUDIENCE,
    scope: "openid profile user_metadata email",
    redirect_uri: `${window.location.origin}/callback`,
    useRefreshTokens: true,
    cacheLocation: "localstorage",
});


var getUserData = async () => {
    const isAuthenticated = await webAuth.isAuthenticated();
    if (isAuthenticated) {
        var user = await webAuth.getUser();
        var token = await webAuth.getTokenSilently();
        const parsedUser = {
            name: user.name,
            lastName: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            postalCode: "",
            state: "",
            zipCode: "",
            ...user,
            ...user["https://mybuddiestrip.com/user_metadata"],
            roles: user["https://mybuddiestrip.com/roles"] || [],
        };
        const resp = { profile: parsedUser, token: token };
        localStorage.setItem("user", JSON.stringify(resp));
        return resp;
    }
};

window.addEventListener('load', function () {
    // { domain: 'mybuddiestrip.auth0.com', client_id: 'njV5gponFA0gn1BExdtYOyVIwwmHYD0v', audience: 'https://mybuddiestrip.com/api' }
    console.log(this.localStorage.getItem("user"));
    console.log(process.env.DOMAIN)
    const main = Elm.Main.init({
        node: document.getElementById("root"), flags: {
            domain: process.env.DOMAIN,
            now: Date.now(),
            user: JSON.parse(this.localStorage.getItem("user")),
            authentificationCallbackurl: this.localStorage.getItem("callbackUrl")
        }
    });

    //********************************************************** */
    /******          AUTHENTIFICATION            ************** */
    /********************************************************** */

    // Auth0 authorize subscription
    main.ports.auth0authorize.subscribe((url) => {
        localStorage.setItem("callbackUrl", window.location.href);
        webAuth.loginWithRedirect();
    });
    // Log out of Auth0 subscription
    main.ports.auth0logout.subscribe(async function (path) {
        localStorage.removeItem("user");
        webAuth.logout({
            returnTo: `${window.location.origin}/${path || ""}`
        });
    });

    main.ports.handleRedirectCallback.subscribe(async () => {
        await webAuth.handleRedirectCallback();
        const resp = await getUserData();
        console.log(resp);
        main.ports.auth0authResult.send(resp);
    });


    //********************************************************** */ 
    /******          AUTHENTIFICATION  FINISHED          ******* */
    /********************************************************** */
});




