port module Main exposing (Model, inquiryProposalPage, main)

import Auth0 exposing (AuthenticationState(..), isExpired)
import Authentication
import Banners
import Browser exposing (Document)
import Browser.Navigation as Nav exposing (Key)
-- import Chart as C
-- import Chart.Attributes as CA
import Configuration exposing (Configuration)
import Customer exposing (Customer)
import Debounce
import Destination exposing (GoogleCity, GooglePrediction)
import Dict
import Expedia
import Expedia.Car as ExpediaCar
import File exposing (File)
import GolfCourse exposing (GolfCourse)
import Html
import Html.Attributes as Attrs
import Html.Events as Events
import Http exposing (Error(..))
import Json.Decode as De
import Json.Decode.Pipeline as De
import Json.Encode exposing (Value)
import List.Extra as List
import Logs exposing (writtenByToStr)
import Math.Percentage as Percentage
import Package.Calcul as Calcul
import Platform.Cmd as Cmd
import ProposalGenerator exposing (Parameter, ProposalSetting)
import RemoteData exposing (RemoteData(..), WebData)
import RequestedPackage exposing (CustomerId(..), DetailDescription(..), InquiryId(..), Proposal, ProposalId(..), RequestPackage, RequestPackageMsg, Stats)
import Route
import String
import Task
import Time exposing (Month(..), Posix, millisToPosix)
import Time.Extra2 exposing (timeAgo, toIsoDate, toLetterAbbrMonth)
import UI.Button as UI
import UI.Dropdown exposing (dropdown_deprecateds)
import UI.GaleryPhoto as GaleryPhoto
import UI.Input as Input exposing (input)
import UI.Layout as Layout exposing (layout)
import UI.Loader exposing (loader)
import UI.Log
import UI.Modal exposing (modal)
import UI.SearchDropdown exposing (searchDropdown)
import UI.Svg.Status
import UI.Tooltips exposing (tooltip)
import Url exposing (Url)
import Utils.OneToTen as OneToTen
import Utils.Stars as Stars


defaultIntroduction : String -> String
defaultIntroduction destination =
    "My name is Bryce and It would be my pleasure to help you plan your golfing experience to " ++ destination ++ "⛳"


defaultConclusion : String
defaultConclusion =
    "Please review the attached proposal for more information, including a detailed itinerary and pricing. If you have any questions or concerns, please do not hesitate to contact us. We are committed to providing you with an exceptional golf travel experience and look forward to hearing from you soon."


type alias Model =
    { navigationKey : Key
    , config : Configuration
    , requestedPackages : WebData (List RequestPackage)
    , requestedPackage : WebData RequestPackage
    , currentProposal : Maybe Proposal
    , customer : WebData Customer
    , customers : WebData (List Customer)
    , stats : WebData (List Stats)
    , predictions : WebData (List GooglePrediction)
    , city : WebData GoogleCity
    , email : String
    , debounce : Debounce.Debounce String
    , emailPreview : WebData String
    , today : Posix
    , rooms :
        Dict.Dict
            Int
            { count : Int
            , room :
                { name : String
                , price : Float
                }
            }

    -- Hotels
    , hotel : WebData Expedia.Hotel
    , hotels : WebData (List Expedia.HotelLocation)
    , selectedHotel : Maybe Expedia.HotelLocation
    , images : WebData (List Expedia.ImageResponse)
    , hotelsFiltered : List Expedia.HotelLocation

    -- Cars
    , cars : WebData ExpediaCar.Response
    , selectedCar : Maybe ExpediaCar.Car
    , carsFiltered : List ExpediaCar.Car
    , carName : String

    -- GolfCourse
    , golfCourse : WebData GolfCourse
    , golfCourses : WebData (List GolfCourse)
    , selectedGolfCourse : Maybe GolfCourse
    , golfCourseFiltered : List GolfCourse
    , hotelName : String
    , golfCourseName : String

    -- Quote Builder
    , title : String
    , description : String
    , selectedImages : List String
    , price : String
    , carPrice : String
    , hotelPrice : String
    , fee : String
    , errors : List String
    , bed : String
    , refundable : String
    , stars : String
    , address : String
    , golfCourseStr : String
    , banner : Maybe Banners.Banner
    , golfCoursesBuilder :
        List
            { name : String
            , price : String
            }

    -- Other
    , route : Route.Route
    , x : String
    , y : String
    , emailFilter : Maybe String
    , statusesFilter : List String
    , modalToShow : Maybe String
    , dropdownToShow : Maybe String
    , toggledSection : List String
    , banners : WebData (List Banners.Banner)
    , bannerName : String
    , bannerFile : WebData Banners.Photo

    -- Proposal Generator
    , generatorSetting : WebData ProposalSetting
    }


type Msg
    = ClickedLink Browser.UrlRequest
    | ChangedUrl Url
    | SelectX String
    | SelectY String
    | SetStatus RequestPackage String
    | SetEmailFilter String
    | SetHotelFilter String
    | SetStatusFilter String
    | DebounceSetHotelFilter Debounce.Msg
    | SetRoom RequestPackage Expedia.Hotel Int Expedia.RoomType String
    | SetGolfCourseName String
    | SetRequestPackage RequestPackageMsg
    | SetIntroduction RequestPackage ProposalId String
    | SetCurrency RequestPackage ProposalId String
    | SelectBanner RequestPackage ProposalId Banners.Banner
    | SetBed String
    | SetRefundable String
    | SetStars String
    | SetAddress String
    | SetHotelPrice String
    | SetFee String
    | SetCarPrice String
    | SetGolfCourseNameById Int String
    | SetGolfCoursePrice Int String
    | SetFile String (List File)
    | SetBannerFile (List File)
    | HandleBannerFile (WebData Banners.Photo)
    | AddHotelProposal InquiryId ProposalId
    | AddNewProposal Route.Route RequestPackage ProposalId Int Int
    | SaveProposal RequestPackage
    | SaveCustomer Customer
    | SetCustomerNote Customer String
    | SetCustomerPhone Customer String
    | SetCustomerEmail Customer String
    | DeleteHotel Route.Route RequestPackage ProposalId Int Int
    | DeleteGolfCourse Route.Route RequestPackage ProposalId Int Int
    | SearchCustomer
    | OnClickGenerate
    | CreateGolfCourse
    | CreateBanner
    | RemoveBanner String
    | SetBannerName String
    | ValidateGolfCourseName
    | HandleRequestedPackages (WebData (List RequestPackage))
    | HandleRequestedPackage (Maybe Route.Route) (WebData RequestPackage)
    | HandleCustomer (WebData Customer)
    | HandleCustomers (WebData (List Customer))
    | HandleGolfCourses (WebData (List GolfCourse))
    | HandleGolfCourse (WebData GolfCourse)
    | HandleGolfCourseCreated (WebData GolfCourse)
    | HandlePutRequestedPackage (Maybe Route.Route) (WebData RequestPackage)
    | HandlePutCustomer (WebData Customer)
    | HandleEmailPreview (WebData String)
    | HandleGetStats (WebData (List Stats))
    | HandlePredictions (WebData (List GooglePrediction))
    | HandleImages (WebData (List Expedia.ImageResponse))
    | HandleCity (WebData GoogleCity)
    | HandleHotels (WebData (List Expedia.HotelLocation))
    | LoadHotel RequestPackage Proposal Expedia.HotelLocation
    | HandleHotel (WebData Expedia.Hotel)
    | HandleBanners (WebData (List Banners.Banner))
    | HandleBanner (WebData Banners.Banner)
    | HandleBannerDeleted
    | HandleGeneratorSetting (WebData ProposalSetting)
    | AuthenticationMsg Authentication.Msg
    | ToggleSection String
    | SendTo String RequestPackage Int
    | SendReminder RequestPackage Int
      -- Quote Builder
    | SetImages String
    | SetEmail String
    | HandleNow Posix
    | RemoveImage RequestPackage ProposalId Int String
      -- Settings
    | SaveSetting ProposalSetting
    | AddSetting ProposalSetting
    | SetSettingMinRating ProposalSetting Parameter Int String
    | SetSettingPersonPerRoom ProposalSetting Parameter Int String
    | SetSettingPercentageForHotel ProposalSetting Parameter Int String
    | None


debounceConfig : Debounce.Config Msg
debounceConfig =
    { strategy = Debounce.later 1000
    , transform = DebounceSetHotelFilter
    }


toAuthenticate : ( Model, Cmd Msg ) -> ( Model, Cmd Msg )
toAuthenticate ( model, cmdMsg ) =
    case Configuration.user model.config of
        Auth0.LoggedIn l ->
            if Auth0.isExpired model.today l then
                ( model, auth0authorize Auth0.defaultOpts |> Cmd.map AuthenticationMsg )

            else
                ( model, cmdMsg )

        Auth0.LoggedOut ->
            ( model, auth0authorize Auth0.defaultOpts |> Cmd.map AuthenticationMsg )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        ClickedLink urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Nav.pushUrl model.navigationKey <| Url.toString url )

                Browser.External href ->
                    ( model, Nav.load href )

        ChangedUrl url ->
            case Route.fromUrl url of
                Just Route.Home ->
                    toAuthenticate
                        ( { model | route = Route.Home, stats = Loading }, RequestedPackage.getStats model.config model.x model.y HandleGetStats )

                Just (Route.Customer (CustomerId id)) ->
                    toAuthenticate
                        ( { model | route = Route.Customer (CustomerId id), requestedPackage = Loading }, Cmd.batch [ Customer.get model.config id HandleCustomer, RequestedPackage.search model.config ("{ \"customerId\" : \"" ++ id ++ "\" }") "[]" HandleRequestedPackages ] )

                Just Route.Customers ->
                    toAuthenticate
                        ( { model | route = Route.Customers, requestedPackages = Loading }, Customer.search model.config "{}" HandleCustomers )

                Just (Route.Inquiry customerId id) ->
                    toAuthenticate
                        ( { model | route = Route.Inquiry customerId id, requestedPackage = Loading }
                        , RequestedPackage.get model.config id (HandleRequestedPackage Nothing)
                        )

                Just (Route.InquiryProposal customerId inquiryId (ProposalId proposalId) inquiryRoute hash) ->
                    let
                        route =
                            Route.InquiryProposal customerId inquiryId (ProposalId proposalId) inquiryRoute hash

                        mNewProposal =
                            model.requestedPackage
                                |> RemoteData.toMaybe
                                |> Maybe.andThen (.proposals >> List.getAt proposalId)

                        defaultProposal_ =
                            defaultProposal (model.requestedPackage |> RemoteData.toMaybe |> Maybe.map .destination |> Maybe.withDefault "")

                        ( requestedPackage, newProposal ) =
                            model.requestedPackage
                                |> RemoteData.map
                                    (\x ->
                                        mNewProposal
                                            |> Maybe.map (\p -> ( Success x, p ))
                                            |> Maybe.withDefault ( Success { x | proposals = List.append x.proposals [ defaultProposal_ ] }, defaultProposal_ )
                                    )
                                |> RemoteData.withDefault ( model.requestedPackage, defaultProposal_ )

                        { hotel, cmds } =
                            case inquiryRoute of
                                Route.ProposedHotel detailId _ ->
                                    { flight = Nothing
                                    , hotel = newProposal.hotels |> List.getAt detailId
                                    , car = Nothing
                                    , golfCourse = Nothing
                                    , cmds = Cmd.none
                                    }

                                Route.ProposedGolfCourse detailId _ ->
                                    { flight = Nothing
                                    , hotel = Nothing
                                    , car = Nothing
                                    , golfCourse = newProposal.golfCourses |> List.getAt detailId
                                    , cmds = Cmd.none
                                    }

                                Route.Proposed ->
                                    { flight = Nothing
                                    , hotel = Nothing
                                    , car = Nothing
                                    , golfCourse = Nothing
                                    , cmds = Cmd.none
                                    }

                                Route.SelectBanner ->
                                    { flight = Nothing
                                    , hotel = Nothing
                                    , car = Nothing
                                    , golfCourse = Nothing
                                    , cmds = Banners.getAll model.config {} HandleBanners
                                    }

                        filteredHotels =
                            hotel
                                |> Maybe.map (\t -> model.hotels |> RemoteData.withDefault [] |> List.filter (\s -> String.contains t.name s.propertyName))
                                |> Maybe.withDefault []

                        selectedHotel =
                            if (filteredHotels |> List.length) == 1 then
                                filteredHotels |> List.head

                            else
                                Nothing

                        mapeedHotel =
                            hotel
                                |> Maybe.map
                                    (\s ->
                                        case s.description of
                                            AllDetails des ->
                                                { beds = des.beds
                                                , bed = des.bed
                                                , address = des.address
                                                , stars = des.stars
                                                , refundable = des.refundable
                                                , golfCourseObjs = des.golfCourseObjs
                                                , golfCourses = des.golfCourses
                                                , car = des.car
                                                , totalPrice = s.price
                                                , hotelPrice = des.hotelPrice

                                                --
                                                , name = s.name
                                                , title = s.title
                                                , photos = s.photos
                                                }

                                            AsString _ ->
                                                { beds = []
                                                , bed = ""
                                                , address = ""
                                                , stars = ""
                                                , refundable = ""
                                                , golfCourseObjs = []
                                                , golfCourses = ""
                                                , car = { name = "", price = 0.0 }
                                                , hotelPrice = 0.0
                                                , name = s.name
                                                , title = s.title
                                                , photos = s.photos
                                                , totalPrice = Nothing
                                                }

                                            DetailDescription des ->
                                                { name = s.name
                                                , title = s.title
                                                , photos = s.photos
                                                , bed = des.bed
                                                , address = des.address
                                                , refundable = des.refundable
                                                , golfCourses = des.golfCourses
                                                , stars = des.stars
                                                , hotelPrice = 0.0
                                                , beds = []
                                                , golfCourseObjs = des.golfCourses |> String.split "," |> List.map (\t -> { name = String.trim t, price = 0 })
                                                , car = { name = "", price = 0.0 }
                                                , totalPrice = s.price
                                                }
                                    )
                                |> Maybe.withDefault
                                    { beds = []
                                    , bed = ""
                                    , address = ""
                                    , stars = ""
                                    , refundable = ""
                                    , golfCourseObjs = []
                                    , golfCourses = ""
                                    , car = { name = "", price = 0.0 }
                                    , hotelPrice = 0.0
                                    , name = ""
                                    , title = ""
                                    , photos = []
                                    , totalPrice = Nothing
                                    }

                        ( hotelLoaded, cmdsHotel ) =
                            case Maybe.map2 (\c h -> { latitude = c.geometry.location.lat, longitude = c.geometry.location.lng, query = h.name }) (model.city |> RemoteData.toMaybe) hotel of
                                Just h ->
                                    ( Loading, Expedia.getHotelsByLocation model.config { latitude = h.latitude, longitude = h.longitude, query = h.query } HandleHotels )

                                Nothing ->
                                    ( NotAsked, Cmd.none )
                    in
                    toAuthenticate
                        ( { model
                            | route = route
                            , currentProposal = mNewProposal
                            , hotel = hotelLoaded
                            , hotelName = mapeedHotel.name
                            , carName = mapeedHotel.name
                            , golfCourseName = mapeedHotel.name
                            , title = mapeedHotel.title
                            , hotelPrice = mapeedHotel.hotelPrice |> String.fromFloat
                            , carPrice = mapeedHotel.car.price |> String.fromFloat
                            , description = ""
                            , bed = mapeedHotel.bed
                            , price = mapeedHotel.totalPrice |> Maybe.withDefault 0.0 |> String.fromFloat
                            , address = mapeedHotel.address
                            , refundable = mapeedHotel.refundable
                            , stars = mapeedHotel.stars
                            , email = model.requestedPackage |> RemoteData.map .email |> RemoteData.withDefault ""
                            , requestedPackage = requestedPackage
                            , selectedImages = mapeedHotel.photos
                            , hotelsFiltered = filteredHotels
                            , golfCourseStr = mapeedHotel.golfCourseObjs |> List.map .name |> String.join ", "
                            , golfCoursesBuilder = mapeedHotel.golfCourseObjs |> List.map (\s -> { name = s.name, price = s.price |> String.fromFloat })
                            , selectedHotel = selectedHotel
                            , images =
                                if String.isEmpty mapeedHotel.name then
                                    NotAsked

                                else
                                    Loading
                          }
                        , Cmd.batch
                            [ cmds
                            , cmdsHotel
                            , if Just inquiryId == (model.requestedPackage |> RemoteData.toMaybe |> Maybe.map .id |> Maybe.map InquiryId) then
                                selectedHotel
                                    |> Maybe.map (\h -> Expedia.getImages model.config h.propertyId.expedia HandleImages)
                                    |> Maybe.withDefault Cmd.none

                              else
                                RequestedPackage.get model.config inquiryId (HandleRequestedPackage (Just route))
                            , case hash of
                                Route.SendEmailHash ->
                                    RequestedPackage.preview model.config inquiryId (ProposalId proposalId) HandleEmailPreview

                                _ ->
                                    Cmd.none
                            ]
                        )

                Just Route.NewRequest ->
                    toAuthenticate
                        ( { model | route = Route.NewRequest, requestedPackages = Loading, statusesFilter = [ "waiting-proposal" ] }
                        , RequestedPackage.search model.config ("{ \"status\": \"waiting-proposal\", \"departureDate\": {\"$gt\": \"" ++ toIsoDate Time.utc model.today ++ "\"} }") "[]" HandleRequestedPackages
                          -- , RequestedPackage.search model.config ("{ \"$or\": [{ \"status\": \"new\" },{ \"status\": \"waiting-proposal\" }, { \"status\" : { \"$exists\" : false }}, { \"status\" : { \"$eq\" : \"\" }}],  \"departureDate\": {\"$gt\": \"" ++ toIsoDate Time.utc model.today ++ "\"} }") HandleRequestedPackages
                        )

                Just (Route.Callback params) ->
                    ( model
                    , if String.isEmpty params.code && String.isEmpty params.state then
                        Cmd.none

                      else
                        Cmd.batch
                            [ handleRedirectCallback ()
                            ]
                    )

                Just Route.Banners ->
                    toAuthenticate ( { model | route = Route.Banners }, Banners.getAll model.config {} HandleBanners )

                Just Route.NewBanners ->
                    toAuthenticate ( { model | route = Route.NewBanners, golfCourses = NotAsked }, Cmd.none )

                Just Route.GolfCourses ->
                    toAuthenticate ( { model | route = Route.GolfCourses }, GolfCourse.getAll model.config {} HandleGolfCourses )

                Just Route.NewGolfCourse ->
                    toAuthenticate ( { model | route = Route.NewGolfCourse, golfCourses = NotAsked }, Cmd.none )

                Just (Route.GolfCourse id) ->
                    toAuthenticate ( { model | route = Route.GolfCourse id }, GolfCourse.get model.config id HandleGolfCourse )

                Just Route.ProposalSettings ->
                    toAuthenticate ( { model | route = Route.ProposalSettings }, ProposalGenerator.get model.config HandleGeneratorSetting )

                Just Route.Logout ->
                    let
                        ( _, cmd ) =
                            Authentication.update Authentication.LogOut model.config.user
                    in
                    ( model, Cmd.batch [ Nav.replaceUrl model.navigationKey "/", Cmd.map AuthenticationMsg cmd ] )

                Nothing ->
                    ( model, Nav.replaceUrl model.navigationKey "/" )

        SetStatusFilter str ->
            if String.isEmpty str then
                ( { model | route = Route.NewRequest, requestedPackages = Loading, statusesFilter = [] }
                , RequestedPackage.search model.config ("{\"departureDate\": {\"$gt\": \"" ++ toIsoDate Time.utc model.today ++ "\"}, \"status\": {\"$not\" : {\"$in\": [\"rejected\", \"no-response\", \"completed\", \"confirmed\"]}} , \"status\": { \"$exists\": 1 , \"$ne\" : \"\"} }") "[\"updatedAt\", -1]" HandleRequestedPackages
                )

            else
                ( { model | route = Route.NewRequest, requestedPackages = Loading, statusesFilter = [ str ] }
                , RequestedPackage.search model.config ("{ \"status\": \"" ++ str ++ "\", \"departureDate\": {\"$gt\": \"" ++ toIsoDate Time.utc model.today ++ "\"} }") "[]" HandleRequestedPackages
                )

        HandleNow now ->
            ( { model | today = now }, Cmd.none )

        SetFile id files ->
            ( model, files |> List.map (\file -> GolfCourse.uploadPhoto model.config id file HandleGolfCourse) |> Cmd.batch )

        SetBannerFile files ->
            ( model, files |> List.map (\file -> Banners.uploadPhoto model.config file HandleBannerFile) |> Cmd.batch )

        None ->
            ( model, Cmd.none )

        SelectX str ->
            ( { model | x = str }, Cmd.none )

        SelectY str ->
            ( { model | y = str }, Cmd.none )

        OnClickGenerate ->
            ( { model | stats = Loading }, RequestedPackage.getStats model.config model.x model.y HandleGetStats )

        SetGolfCourseName str ->
            ( { model | golfCourseName = str, golfCourses = NotAsked }, Cmd.none )

        SetRequestPackage msg_ ->
            ( { model | requestedPackage = RemoteData.map (RequestedPackage.update msg_) model.requestedPackage }, Cmd.none )

        SetIntroduction request (ProposalId proposalId) str ->
            request.proposals
                |> List.getAt proposalId
                |> Maybe.map (\proposal -> RequestedPackage.setAt request (ProposalId proposalId) { proposal | introduction = str })
                |> Maybe.map (\r -> ( { model | requestedPackage = Success r }, Cmd.none ))
                |> Maybe.withDefault ( model, Cmd.none )

        SetCurrency request (ProposalId proposalId) str ->
            request.proposals
                |> List.getAt proposalId
                |> Maybe.map (\proposal -> RequestedPackage.setAt request (ProposalId proposalId) { proposal | currency = str })
                |> Maybe.map (\r -> ( { model | requestedPackage = Success r }, Cmd.none ))
                |> Maybe.withDefault ( model, Cmd.none )

        SelectBanner request (ProposalId proposalId) banner ->
            request.proposals
                |> List.getAt proposalId
                |> Maybe.map (\proposal -> RequestedPackage.setAt request (ProposalId proposalId) { proposal | banner = banner.image.url })
                |> Maybe.map (\r -> ( { model | requestedPackage = Success r }, Nav.back model.navigationKey 1 ))
                |> Maybe.withDefault ( model, Cmd.none )

        RemoveImage request (ProposalId proposalId) optionId str ->
            request.proposals
                |> List.getAt proposalId
                |> Maybe.map
                    (\proposal ->
                        RequestedPackage.setAt request
                            (ProposalId proposalId)
                            { proposal
                                | hotels =
                                    List.getAt optionId proposal.hotels
                                        |> Maybe.map (\h -> List.setAt optionId { h | photos = List.filter (\p -> p == str |> not) h.photos } proposal.hotels)
                                        |> Maybe.withDefault proposal.hotels
                            }
                    )
                |> Maybe.map (\r -> ( { model | requestedPackage = Success r }, Cmd.none ))
                |> Maybe.withDefault ( model, Cmd.none )

        SetHotelPrice str ->
            let
                hotelPrice =
                    str |> String.toFloat |> Maybe.withDefault 0.0
            in
            ( { model
                | hotelPrice = str
                , price = Calcul.price { hotel = hotelPrice, fee = model.fee |> String.toFloat |> Maybe.withDefault 0.0, car = model.carPrice |> String.toFloat |> Maybe.withDefault 0.0, golfCourse = model.golfCoursesBuilder |> List.map (.price >> String.toFloat >> Maybe.withDefault 0.0) |> List.sum, numberOfPerson = model.requestedPackage |> RemoteData.map (.numberOfTraveller >> String.toInt >> Maybe.withDefault 1) |> RemoteData.withDefault 1 } |> String.fromFloat
              }
            , Cmd.none
            )

        SetFee str ->
            ( { model
                | fee = str
                , price = Calcul.priceUnvalidated { hotel = model.hotelPrice, fee = str, car = model.carPrice, golfCourses = model.golfCoursesBuilder |> List.map .price, numberOfPerson = model.requestedPackage |> RemoteData.map (.numberOfTraveller >> String.toInt >> Maybe.withDefault 1) |> RemoteData.withDefault 1 } |> String.fromFloat
              }
            , Cmd.none
            )

        SetCarPrice str ->
            ( { model
                | carPrice = str
                , price = Calcul.priceUnvalidated { hotel = model.hotelPrice, fee = model.fee, car = str, golfCourses = model.golfCoursesBuilder |> List.map .price, numberOfPerson = model.requestedPackage |> RemoteData.map (.numberOfTraveller >> String.toInt >> Maybe.withDefault 1) |> RemoteData.withDefault 1 } |> String.fromFloat
              }
            , Cmd.none
            )

        CreateGolfCourse ->
            ( { model | golfCourse = Loading }, GolfCourse.create model.config model.golfCourseName HandleGolfCourseCreated )

        CreateBanner ->
            case model.bannerFile of
                Success f ->
                    ( model, Banners.create model.config { name = model.bannerName, photo = f } HandleBanner )

                _ ->
                    ( model, Cmd.none )

        RemoveBanner bannerId ->
            ( model, Banners.delete model.config bannerId HandleBannerDeleted )

        SetBannerName str ->
            ( { model | bannerName = str }, Cmd.none )

        HandleBannerFile f ->
            ( { model | bannerFile = f }, Cmd.none )

        HandleBannerDeleted ->
            ( { model | banners = Loading }, Banners.getAll model.config {} HandleBanners )

        ValidateGolfCourseName ->
            ( { model | golfCourses = Loading }, GolfCourse.validateName model.config model.golfCourseName HandleGolfCourses )

        HandleGolfCourseCreated r ->
            ( { model | golfCourse = r }, r |> RemoteData.map (\s -> Nav.pushUrl model.navigationKey (Route.toUrl (Route.GolfCourse s.id))) |> RemoteData.withDefault Cmd.none )

        HandleRequestedPackages ls ->
            ( { model | requestedPackages = ls }, Cmd.none )

        HandleRequestedPackage route r ->
            ( { model | requestedPackage = r }
            , r
                |> RemoteData.map
                    (\s ->
                        [ Destination.getByQuery model.config s.destination HandlePredictions
                        , GolfCourse.getAll model.config {} HandleGolfCourses
                        ]
                    )
                |> RemoteData.withDefault []
                |> (::) (route |> Maybe.map (\r2 -> Nav.replaceUrl model.navigationKey (Route.toUrl r2)) |> Maybe.withDefault Cmd.none)
                |> Cmd.batch
            )

        HandleCustomers ls ->
            ( { model | customers = ls }, Cmd.none )

        HandleCustomer r ->
            ( { model | customer = r }, Cmd.none )

        HandleGolfCourse r ->
            ( { model | golfCourse = r }, Cmd.none )

        HandlePredictions p ->
            ( { model | predictions = p }
            , p
                |> RemoteData.toMaybe
                |> Maybe.andThen List.head
                |> Maybe.map (\s -> Destination.getById model.config s.placeId HandleCity)
                |> Maybe.withDefault Cmd.none
            )

        HandleGeneratorSetting p ->
            ( { model | generatorSetting = p }, Cmd.none )

        HandleCity c ->
            ( { model | city = c }
            , c
                |> RemoteData.map2
                    (\_ _ ->
                        [-- Expedia.getHotelsByLocation model.config { latitude = s.geometry.location.lat, longitude = s.geometry.location.lng, query = "" } HandleHotels
                         -- , ExpediaCar.getAll model.config { pickupLat = s.geometry.location.lat, pickupLon = s.geometry.location.lng, pickupDate = r.departureDate, dropoffDate = r.returnDate } HandleCars
                        ]
                    )
                    model.requestedPackage
                |> RemoteData.withDefault []
                |> Cmd.batch
            )

        HandleGolfCourses r ->
            let
                filtered =
                    r |> RemoteData.withDefault [] |> List.filter (\s -> String.contains model.golfCourseName s.name)
            in
            ( { model
                | golfCourses = r
                , golfCourseFiltered = filtered
                , selectedGolfCourse =
                    if List.length filtered == 1 then
                        List.head filtered

                    else
                        Nothing
              }
            , Cmd.none
            )

        HandleBanners r ->
            ( { model | banners = r }, Cmd.none )

        HandleBanner r ->
            r
                |> RemoteData.map (\_ -> ( model, Nav.pushUrl model.navigationKey <| Route.toUrl Route.Banners ))
                |> RemoteData.withDefault ( model, Cmd.none )

        HandleHotels h ->
            let
                filtered =
                    h |> RemoteData.withDefault [] |> List.filter (\s -> String.contains model.hotelName s.propertyName)
            in
            ( { model
                | hotels = h
                , hotelsFiltered = filtered
                , selectedHotel = filtered |> List.head
              }
            , if List.length filtered > 1 then
                filtered
                    |> List.head
                    |> Maybe.map3
                        (\request p s ->
                            Expedia.getHotelById model.config
                                { checkIn = request.departureDate
                                , checkOut = request.returnDate
                                , ecomHotelIds = s.propertyId.expedia
                                , hcomHotelIds = s.propertyId.hcom
                                , currency = p.currency
                                }
                                HandleHotel
                        )
                        (model.requestedPackage |> RemoteData.toMaybe)
                        model.currentProposal
                    |> Maybe.withDefault Cmd.none

              else
                Cmd.none
            )

        HandleImages images ->
            ( { model | images = images }, Cmd.none )

        SetStatus request status ->
            ( model, RequestedPackage.put model.config { request | status = status } (HandlePutRequestedPackage Nothing) )

        SetHotelFilter str ->
            let
                -- Push your values here.
                ( debounce, cmd ) =
                    Debounce.push debounceConfig str model.debounce

                hotels =
                    model.hotels |> RemoteData.withDefault [] |> List.filter (\s -> String.contains (String.toLower str) (String.toLower s.propertyName))
            in
            ( { model
                | hotelName = str
                , hotelsFiltered = hotels
                , debounce = debounce
                , hotels =
                    if hotels |> List.isEmpty then
                        Loading

                    else
                        model.hotels
                , dropdownToShow = Just "hotels"
              }
            , cmd
            )

        DebounceSetHotelFilter debounceMsg ->
            let
                ( debounce, cmd ) =
                    Debounce.update
                        debounceConfig
                        (Debounce.takeLast <|
                            \last ->
                                if model.hotelsFiltered |> List.isEmpty then
                                    model.city |> RemoteData.map (\c -> Expedia.getHotelsByLocation model.config { latitude = c.geometry.location.lat, longitude = c.geometry.location.lng, query = last } HandleHotels) |> RemoteData.withDefault Cmd.none

                                else
                                    Cmd.none
                        )
                        debounceMsg
                        model.debounce
            in
            ( { model | debounce = debounce }
            , cmd
            )

        SetRoom _ hotel index room stringNumber ->
            let
                n =
                    stringNumber |> String.toInt |> Maybe.withDefault 0

                rooms =
                    Dict.insert index
                        { count = n
                        , room =
                            { price =
                                room.price.totalPriceWithHotelFees.value
                                    |> String.toFloat
                                    |> Maybe.withDefault 0.0
                            , name = room.bedTypeOptions |> List.head |> Maybe.map .description |> Maybe.withDefault ""
                            }
                        }
                        model.rooms

                hotelPrice =
                    rooms
                        |> Dict.values
                        |> List.map
                            (\x ->
                                x.room.price
                                    |> Basics.ceiling
                                    |> (*) x.count
                            )
                        |> List.sum
                        |> Basics.toFloat
            in
            ( { model
                | rooms = rooms
                , bed =
                    rooms
                        |> Dict.values
                        |> List.map
                            (\x ->
                                if x.count > 1 then
                                    "(" ++ (x.count |> String.fromInt) ++ "x) " ++ x.room.name

                                else if x.count == 1 then
                                    x.room.name

                                else
                                    ""
                            )
                        |> String.join ", "
                , address = hotel.location.address.address1 ++ ", " ++ hotel.location.address.city ++ ", " ++ hotel.location.address.province
                , stars = (hotel.guestRating |> String.toFloat |> Maybe.withDefault 0.0 |> (*) 2.0 |> String.fromFloat) ++ " Guest Rating"
                , hotelPrice = hotelPrice |> String.fromFloat
                , price = Calcul.priceUnvalidated { hotel = hotelPrice |> String.fromFloat, fee = model.fee, car = model.carPrice, golfCourses = model.golfCoursesBuilder |> List.map .price, numberOfPerson = model.requestedPackage |> RemoteData.map (.numberOfTraveller >> String.toInt >> Maybe.withDefault 1) |> RemoteData.withDefault 1 } |> String.fromFloat
                , refundable =
                    let
                        date =
                            room.ratePlans |> List.head |> Maybe.andThen (.cancellationPolicy >> .freeCancellationEndDateTime)

                        month =
                            date |> Maybe.map (toLetterAbbrMonth Time.utc)

                        day =
                            date |> Maybe.map (Time.toDay Time.utc)

                        year =
                            date |> Maybe.map (Time.toYear Time.utc)
                    in
                    Maybe.map3 (\m d y -> "Fully refundable before " ++ m ++ " " ++ String.fromInt d ++ ", " ++ String.fromInt y) month day year |> Maybe.withDefault "Not refundable"
              }
            , Cmd.none
            )

        LoadHotel request proposal hotel ->
            ( { model
                | dropdownToShow = Nothing
                , hotelName = hotel.propertyName
                , selectedHotel = Just hotel
              }
            , Cmd.batch
                [ Expedia.getImages model.config hotel.propertyId.expedia HandleImages
                , Expedia.getHotelById model.config
                    { checkIn = request.departureDate
                    , checkOut = request.returnDate
                    , ecomHotelIds = hotel.propertyId.expedia
                    , hcomHotelIds = hotel.propertyId.hcom
                    , currency = proposal.currency
                    }
                    HandleHotel
                ]
            )

        HandleHotel rHotel ->
            ( { model | hotel = rHotel }, Cmd.none )

        AddHotelProposal requestId proposalId ->
            ( { model | requestedPackage = Loading }, RequestedPackage.propose model.config requestId proposalId (HandleRequestedPackage Nothing) )

        AddNewProposal route request (ProposalId proposalId) version detailId ->
            let
                newProposal =
                    List.getAt proposalId request.proposals
                        |> Maybe.map
                            (\t ->
                                { t
                                    | hotels =
                                        if detailId < List.length t.hotels then
                                            List.setAt detailId
                                                { name = model.hotelName
                                                , title = model.title
                                                , description =
                                                    AllDetails
                                                        { bed = model.bed
                                                        , stars = model.stars
                                                        , address = model.address
                                                        , refundable = model.refundable
                                                        , fee = model.fee |> String.toFloat |> Maybe.withDefault 0.0
                                                        , hotelPrice = model.hotelPrice |> String.toFloat |> Maybe.withDefault 0.0
                                                        , hotelUrl = model.hotel |> RemoteData.toMaybe |> Maybe.map (.links >> .webSearchResult >> .href) |> Maybe.withDefault ""
                                                        , golfCourses =
                                                            model.golfCoursesBuilder
                                                                |> List.filter (\s -> s.name |> String.isEmpty |> not)
                                                                |> List.map .name
                                                                |> String.join ", "
                                                        , golfCourseObjs =
                                                            model.golfCoursesBuilder
                                                                |> List.filter (\s -> s.name |> String.isEmpty |> not)
                                                                |> List.map (\s -> { name = s.name, price = s.price |> String.toFloat |> Maybe.withDefault 0.0 })
                                                        , car = { name = "", price = model.carPrice |> String.toFloat |> Maybe.withDefault 0.0 }
                                                        , beds =
                                                            model.rooms
                                                                |> Dict.values
                                                                |> List.map
                                                                    (\s ->
                                                                        { name =
                                                                            s.room.name
                                                                        , price =
                                                                            s.room.price
                                                                        , count = s.count
                                                                        }
                                                                    )
                                                        }
                                                , photos = model.selectedImages
                                                , price = model.price |> String.toFloat
                                                }
                                                t.hotels

                                        else
                                            { name = model.hotelName
                                            , title = model.title
                                            , description =
                                                AllDetails
                                                    { bed = model.bed
                                                    , stars = model.stars
                                                    , address = model.address
                                                    , refundable = model.refundable
                                                    , fee = model.fee |> String.toFloat |> Maybe.withDefault 0.0
                                                    , hotelPrice = model.hotelPrice |> String.toFloat |> Maybe.withDefault 0.0
                                                    , hotelUrl = model.hotel |> RemoteData.toMaybe |> Maybe.map (.links >> .webSearchResult >> .href) |> Maybe.withDefault ""
                                                    , golfCourses =
                                                        model.golfCoursesBuilder
                                                            |> List.filter (\s -> s.name |> String.isEmpty |> not)
                                                            |> List.map .name
                                                            |> String.join ", "
                                                    , golfCourseObjs =
                                                        model.golfCoursesBuilder
                                                            |> List.filter (\s -> s.name |> String.isEmpty |> not)
                                                            |> List.map (\s -> { name = s.name, price = s.price |> String.toFloat |> Maybe.withDefault 0.0 })
                                                    , car = { name = "", price = model.carPrice |> String.toFloat |> Maybe.withDefault 0.0 }
                                                    , beds =
                                                        model.rooms
                                                            |> Dict.values
                                                            |> List.map
                                                                (\s ->
                                                                    { name =
                                                                        s.room.name
                                                                    , price =
                                                                        s.room.price
                                                                    , count = s.count
                                                                    }
                                                                )
                                                    }
                                            , photos = model.selectedImages
                                            , price = model.price |> String.toFloat
                                            }
                                                :: t.hotels
                                }
                            )
            in
            ( { model | requestedPackage = Loading }
            , Cmd.batch
                [ RequestedPackage.put model.config
                    { request
                        | proposals =
                            newProposal
                                |> Maybe.map
                                    (\pro ->
                                        if List.length request.proposals >= (version + 1) then
                                            List.setAt version pro request.proposals

                                        else
                                            request.proposals ++ [ pro ]
                                    )
                                |> Maybe.withDefault request.proposals
                    }
                    (HandlePutRequestedPackage
                        (Just route)
                    )
                ]
            )

        SaveProposal request ->
            ( { model | requestedPackage = Loading }
            , Cmd.batch
                [ RequestedPackage.put model.config
                    request
                    (HandlePutRequestedPackage
                        Nothing
                    )
                ]
            )

        SaveCustomer customer ->
            ( { model | customer = Loading }
            , Cmd.batch
                [ Customer.put model.config
                    customer
                    HandlePutCustomer
                ]
            )

        SetCustomerNote customer notes ->
            ( { model | customer = Success { customer | notes = notes } }, Cmd.none )

        SetCustomerPhone customer phone ->
            ( { model | customer = Success { customer | phoneNumber = phone } }, Cmd.none )

        SetCustomerEmail customer email ->
            ( { model | customer = Success { customer | email = email } }, Cmd.none )

        HandlePutCustomer mC ->
            ( { model | customer = mC }, Cmd.none )

        DeleteHotel route request (ProposalId proposalid) version id ->
            let
                newRequest =
                    List.getAt proposalid request.proposals |> Maybe.map (\proposal -> { request | proposals = List.setAt version { proposal | hotels = List.removeAt id proposal.hotels } request.proposals })
            in
            ( { model | requestedPackage = Loading }, Cmd.batch [ newRequest |> Maybe.map (\req -> RequestedPackage.put model.config req (HandleRequestedPackage (Just route))) |> Maybe.withDefault Cmd.none, Nav.replaceUrl model.navigationKey (Route.toUrl route) ] )

        DeleteGolfCourse route request (ProposalId proposalid) version id ->
            let
                newRequest =
                    List.getAt proposalid request.proposals
                        |> Maybe.map
                            (\proposal ->
                                { request | proposals = List.setAt version { proposal | golfCourses = List.removeAt id proposal.golfCourses } request.proposals }
                            )
            in
            ( { model | requestedPackage = Loading }, Cmd.batch [ newRequest |> Maybe.map (\req -> RequestedPackage.put model.config req (HandleRequestedPackage (Just route))) |> Maybe.withDefault Cmd.none, Nav.replaceUrl model.navigationKey (Route.toUrl route) ] )

        SetEmailFilter str ->
            ( { model
                | emailFilter =
                    if String.isEmpty str then
                        Nothing

                    else
                        Just str
              }
            , Cmd.none
            )

        SearchCustomer ->
            let
                filter =
                    model.emailFilter |> Maybe.map (\e -> "{ \"email\": { \"$regex\": \"" ++ e ++ "\" } }") |> Maybe.withDefault "{}"
            in
            ( { model | requestedPackages = Loading }, Customer.search model.config filter HandleCustomers )

        HandlePutRequestedPackage mRoute r ->
            ( { model | requestedPackage = r, requestedPackages = RemoteData.map2 (\ls l -> ls |> List.filter (\t -> t.id == l.id |> not)) model.requestedPackages r }
            , mRoute |> Maybe.map (Route.toUrl >> Nav.replaceUrl model.navigationKey) |> Maybe.withDefault Cmd.none
            )

        SendTo email requestedPacakge version ->
            if List.getAt version requestedPacakge.proposals |> Maybe.map .currency |> Maybe.withDefault "" |> String.isEmpty then
                ( { model | errors = [ "A currency need to be selected" ] }, Cmd.none )

            else
                ( { model | requestedPackage = Loading, errors = [] }, RequestedPackage.sendEmail model.config (InquiryId requestedPacakge.id) (ProposalId version) email (HandlePutRequestedPackage Nothing) )

        SendReminder requestedPacakge version ->
            ( { model | requestedPackage = Loading, errors = [] }, RequestedPackage.sendReminder model.config (InquiryId requestedPacakge.id) (ProposalId version) requestedPacakge.email (HandlePutRequestedPackage Nothing) )

        HandleEmailPreview rHtml ->
            ( { model | emailPreview = rHtml }, Cmd.none )

        HandleGetStats r ->
            ( { model | stats = r }, Cmd.none )

        ToggleSection str ->
            if model.toggledSection |> List.any ((==) str) then
                ( { model | toggledSection = model.toggledSection |> List.filter ((==) str >> not) }, Cmd.none )

            else
                ( { model | toggledSection = str :: model.toggledSection }, Cmd.none )

        AuthenticationMsg subMsg ->
            let
                ( authModel, cmd ) =
                    Authentication.update subMsg model.config.user

                cmdnav =
                    case subMsg of
                        Authentication.AuthenticationResult _ ->
                            Nav.replaceUrl model.navigationKey model.config.authentificationCallbackurl

                        _ ->
                            Cmd.none

                config =
                    Configuration.setUser authModel model.config
            in
            ( { model | config = config }, Cmd.batch [ cmdnav, Cmd.map AuthenticationMsg cmd ] )

        SetBed str ->
            ( { model | bed = str }, Cmd.none )

        SetGolfCourseNameById index value ->
            let
                g =
                    List.getAt index model.golfCoursesBuilder
                        |> Maybe.map (\s -> List.setAt index { s | name = value } model.golfCoursesBuilder)
                        |> Maybe.withDefault (List.append model.golfCoursesBuilder [ { name = value, price = "" } ])
            in
            ( { model
                | golfCoursesBuilder = g
                , golfCourseStr = g |> List.map .name |> String.join ", "
              }
            , Cmd.none
            )

        SetGolfCoursePrice index value ->
            let
                golfCourseBuilder =
                    List.getAt index model.golfCoursesBuilder
                        |> Maybe.map (\s -> List.setAt index { s | price = value } model.golfCoursesBuilder)
                        |> Maybe.withDefault (List.append model.golfCoursesBuilder [ { name = "", price = value } ])
            in
            ( { model
                | golfCoursesBuilder = golfCourseBuilder
                , price = Calcul.priceUnvalidated { hotel = model.hotelPrice, fee = model.fee, car = model.carPrice, golfCourses = golfCourseBuilder |> List.map .price, numberOfPerson = model.requestedPackage |> RemoteData.map (.numberOfTraveller >> String.toInt >> Maybe.withDefault 1) |> RemoteData.withDefault 1 } |> String.fromFloat
              }
            , Cmd.none
            )

        SetRefundable str ->
            ( { model | refundable = str }, Cmd.none )

        SetStars str ->
            ( { model | stars = str }, Cmd.none )

        SetAddress str ->
            ( { model | address = str }, Cmd.none )

        SetEmail str ->
            ( { model | email = str }, Cmd.none )

        SetImages i ->
            ( { model
                | selectedImages =
                    if List.any ((==) (getPhotoId i)) (model.selectedImages |> List.map getPhotoId) then
                        List.filter (\s -> getPhotoId s == getPhotoId i |> not) model.selectedImages

                    else
                        i :: model.selectedImages
              }
            , Cmd.none
            )

        -- Settings
        SaveSetting settings ->
            ( { model | generatorSetting = Loading }, ProposalGenerator.put model.config settings HandleGeneratorSetting )

        AddSetting settings ->
            ( { model
                | generatorSetting =
                    { settings
                        | parameters =
                            [ { minRating = Stars.threeStars
                              , personPerRoom = OneToTen.two
                              , percentageOfBudgetForHotel = Percentage.sixty
                              }
                            ]
                                |> (++) settings.parameters
                    }
                        |> RemoteData.Success
              }
            , Cmd.none
            )

        SetSettingMinRating settings setting index value ->
            ( { model
                | generatorSetting =
                    Success
                        { settings
                            | parameters =
                                value
                                    |> Stars.fromString
                                    |> Result.map (\v -> settings.parameters |> List.setAt index { setting | minRating = v })
                                    |> Result.withDefault settings.parameters
                        }
              }
            , Cmd.none
            )

        SetSettingPersonPerRoom settings setting index value ->
            ( { model
                | generatorSetting =
                    Success
                        { settings
                            | parameters =
                                value
                                    |> OneToTen.fromString
                                    |> Result.map (\v -> settings.parameters |> List.setAt index { setting | personPerRoom = v })
                                    |> Result.withDefault settings.parameters
                        }
              }
            , Cmd.none
            )

        SetSettingPercentageForHotel settings setting index value ->
            ( { model
                | generatorSetting =
                    Success
                        { settings
                            | parameters =
                                value
                                    |> Percentage.fromString
                                    |> Result.map (\v -> settings.parameters |> List.setAt index { setting | percentageOfBudgetForHotel = v })
                                    |> Result.withDefault settings.parameters
                        }
              }
            , Cmd.none
            )


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch [ auth0authResult (Authentication.handleAuthResult >> AuthenticationMsg) ]


view : Model -> Document Msg
view model =
    { title = "Admin Portal"
    , body =
        [ Html.header [ Attrs.class "min-h-[6rem] bg-blue-300 w-full sticky top-0 z-40 px-2 flex flex-col lg:flex-row justify-center items-center align-center gap-10 text-4xl bold hover:[&>button]:opacity-50 group hover:py-4" ]
            [ UI.button [ Attrs.class "block lg:hidden" ] [ Html.text "Menu" ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.Home ) ] ] [ Html.a [ Attrs.href "/" ] [ Html.text "Home" ] ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.Customers ) ] ] [ Html.a [ Attrs.href "/customers" ] [ Html.text "Customers" ] ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.GolfCourses ) ] ] [ Html.a [ Attrs.href "/golf-courses" ] [ Html.text "Golf Courses" ] ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.Banners ) ] ] [ Html.a [ Attrs.href "/banners" ] [ Html.text "Banners" ] ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.NewRequest ) ] ] [ Html.a [ Attrs.href "/new-request" ] [ Html.text "Quotes" ] ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.ProposalSettings ) ] ] [ Html.a [ Attrs.href (Route.toUrl Route.ProposalSettings) ] [ Html.text "Generator Settings" ] ]
            , UI.button [ Attrs.class "hidden group-hover:block lg:block", Attrs.classList [ ( "bg-amber-200", model.route == Route.Logout ) ] ] [ Html.a [ Attrs.href (Route.toUrl Route.Logout) ] [ Html.text "Logout" ] ]
            ]
        , case model.route of
            Route.Home ->
                Html.div [ Attrs.class "m-8 lg:ml-96 lg:pr-96 lg:pb-96 lg:mg-96 text-3xl" ] <|
                    case model.stats of
                        Loading ->
                            [ loader
                            ]

                        NotAsked ->
                            [ loader ]

                        Failure e ->
                            [ httpErrorToHtml e ]

                        Success _ ->
                            [ dropdown_deprecateds [ "createdAt", "destination" ] model.x SelectX
                            , dropdown_deprecateds [ "count", "budgetPerPerson" ] model.y SelectY
                            , UI.button [ Attrs.class "bg-blue-300 w-full mt-4 p-2 rounded-lg", Events.onClick OnClickGenerate ] [ Html.text "Generate" ]
                            , Html.div [ Attrs.class "w-auto h-auto text-[0.2rem] pt-10 lg:h-1/6" ] []
                                -- [ C.chart
                                --     []
                                --     [ C.xTicks []
                                --     , C.yTicks []
                                --     , C.binLabels .x [ CA.moveDown 5, CA.rotate 90, CA.alignRight ]
                                --     , C.yLabels []
                                --     , C.bars []
                                --         [ C.bar .y [ CA.opacity 0.8 ]
                                --         ]
                                --         (model.stats
                                --             |> RemoteData.withDefault []
                                --             |> List.sortBy .x
                                --         )
                                --     ]
                                -- ]
                            ]

            Route.Inquiry customerId inquiryId ->
                inquiryPage model customerId inquiryId

            Route.InquiryProposal customerId inquiryId version inquiryRoute hash ->
                inquiryProposalPage model customerId inquiryId version inquiryRoute hash

            Route.Customer _ ->
                customerPage model

            Route.Customers ->
                customerListPage model

            Route.NewRequest ->
                requestView { today = model.today, emailFilter = model.emailFilter, statusesFilter = model.statusesFilter } model.requestedPackages

            Route.Banners ->
                bannersPage model

            Route.NewBanners ->
                Layout.layout []
                    [ Layout.detail []
                        [ Layout.section [ Attrs.class "flex flex-col gap-4" ]
                            [ Layout.sectionContent [ Attrs.class "flex justify-evenly" ]
                                [ input [] [ Input.label [] [ Html.text "Banner Name" ], Input.textInput [ Events.onInput SetBannerName, Attrs.value model.bannerName ] [] ]
                                , input []
                                    [ Input.label [] [ Html.text "Photo" ]
                                    , Input.textInput
                                        [ Attrs.type_ "file"
                                        , Attrs.multiple True
                                        , Events.on "change" (De.map SetBannerFile filesDecoder)
                                        ]
                                        []
                                    ]
                                , case model.bannerFile of
                                    Success _ ->
                                        UI.button [ Events.onClick CreateBanner ] [ Html.text "Create" ]

                                    _ ->
                                        Html.text "Missing Photo"
                                ]
                            ]
                        ]
                    ]

            Route.GolfCourses ->
                layout []
                    [ Layout.table []
                        [ Html.div []
                            [ UI.button [] [ Html.a [ Attrs.href <| Route.toUrl Route.NewGolfCourse ] [ Html.text "Add Golf Course" ] ]
                            ]
                        , Html.div
                            [ Attrs.class "relative"
                            ]
                            [ Html.table
                                [ Attrs.class "w-full text-3xl lg:text-sm text-left text-gray-500 dark:text-gray-400 max-w-full"
                                ]
                                [ Html.thead
                                    [ Attrs.class "text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400l"
                                    ]
                                    [ Html.tr []
                                        [ Html.th
                                            [ Attrs.scope "col"
                                            , Attrs.class "px-2 py-3"
                                            ]
                                            [ Html.text "Name" ]
                                        , Html.th
                                            [ Attrs.scope "col"
                                            , Attrs.class "px-2 py-3"
                                            ]
                                            [ Html.text "Image Counts" ]
                                        , Html.th
                                            [ Attrs.scope "col"
                                            , Attrs.class "px-2 py-3"
                                            ]
                                            []
                                        ]
                                    ]
                                , Html.tbody [] <|
                                    (model.golfCourses
                                        |> RemoteData.withDefault []
                                        |> List.map
                                            (\golfCourse ->
                                                Html.tr
                                                    [ Attrs.class "bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                                    ]
                                                    [ Html.th
                                                        [ Attrs.scope "row"
                                                        , Attrs.class "px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                        ]
                                                        [ Html.text golfCourse.name ]
                                                    , Html.td
                                                        [ Attrs.class "px-2 py-4"
                                                        ]
                                                        [ Html.text <| String.fromInt <| List.length golfCourse.images ]
                                                    , Html.td
                                                        [ Attrs.class "px-2 py-4"
                                                        ]
                                                        [ Html.a [ Attrs.href <| Route.toUrl (Route.GolfCourse golfCourse.id) ] [ Html.text "✎" ] ]
                                                    ]
                                            )
                                    )
                                ]
                            ]
                        ]
                    ]

            Route.GolfCourse id ->
                Layout.layout []
                    [ Layout.detail []
                        [ Layout.section [] <|
                            case model.golfCourse of
                                Failure err ->
                                    [ Layout.sectionContent [] [ httpErrorToHtml err ] ]

                                Loading ->
                                    [ Layout.sectionContent [] [ loader ] ]

                                NotAsked ->
                                    [ Layout.sectionContent [] [ loader ] ]

                                Success golfCourse ->
                                    [ Layout.sectionTitle [] [ Html.text golfCourse.name ]
                                    , Layout.sectionContent [] [ input [] [ Input.label [] [ Html.text "Photos" ], Input.textInput [ Attrs.type_ "file", Attrs.multiple True, Events.on "change" (De.map (SetFile id) filesDecoder) ] [] ] ]
                                    , Layout.sectionContent []
                                        (golfCourse.images
                                            |> List.map
                                                (\s ->
                                                    Html.div
                                                        [ Attrs.class "border relative"
                                                        ]
                                                        [ Html.input [ Attrs.type_ "checkbox", Attrs.id ("zoom" ++ s.url), Attrs.class "hidden peer" ] []
                                                        , Html.label [ Attrs.for ("zoom" ++ s.url), Attrs.class "block w-full lg:w-1/3 overflow-hidden peer-checked:z-50 peer-checked:overflow-visible peer-checked:fixed peer-checked:max-h-screen peer-checked:h-screen peer-checked:w-screen peer-checked:left-0 peer-checked:top-0 peer-checked:flex peer-checked:justify-center peer-checked:items-center" ]
                                                            [ Html.img [ Attrs.src s.url ] []
                                                            ]
                                                        ]
                                                )
                                        )
                                    ]
                        ]
                    ]

            Route.NewGolfCourse ->
                Layout.layout []
                    [ Layout.detail []
                        [ Layout.section [ Attrs.class "text-3xl text-red-300 border p-2 bg-slate-100 font-bold rounded" ]
                            [ Layout.sectionContent []
                                [ case model.golfCourses of
                                    Failure err ->
                                        httpErrorToHtml err

                                    Success ls ->
                                        case ls of
                                            [] ->
                                                Html.span [ Attrs.class "text-green-300" ] [ Html.text "Perfect you can create it" ]

                                            _ ->
                                                Html.text "Name already used"

                                    NotAsked ->
                                        Html.text "You must type something"

                                    Loading ->
                                        Html.text "Pls wait, we checking if name already used"
                                ]
                            ]
                        , Layout.section [ Attrs.class "flex flex-col gap-4" ]
                            [ Layout.sectionContent [ Attrs.class "flex justify-evenly" ]
                                [ input [] [ Input.label [] [ Html.text "Golf Course Name" ], Input.textInput [ Events.onInput SetGolfCourseName, Attrs.value model.golfCourseName ] [] ]
                                , UI.button [ Events.onClick ValidateGolfCourseName ] [ Html.text "Validate Name " ]
                                , UI.button [ Events.onClick CreateGolfCourse, Attrs.disabled ((model.golfCourses == Success [] |> not) || String.isEmpty model.golfCourseName) ] [ Html.text "Create" ]
                                ]
                            ]
                        ]
                    ]

            Route.ProposalSettings ->
                Layout.layout []
                    [ Layout.detail []
                        [ Layout.section [] <|
                            case model.generatorSetting of
                                Failure err ->
                                    [ Layout.sectionContent [] [ httpErrorToHtml err ] ]

                                Loading ->
                                    [ Layout.sectionContent [] [ loader ] ]

                                NotAsked ->
                                    [ Layout.sectionContent [] [ loader ] ]

                                Success settings ->
                                    [ Layout.sectionTitle []
                                        [ Html.text "Generator Settings"
                                        , UI.button [ Attrs.class "ml-10 p-4", Events.onClick (SaveSetting settings) ] [ Html.text "Save" ]
                                        , UI.button [ Attrs.class "ml-10 p-4", Events.onClick (AddSetting settings) ] [ Html.text "Add Attemps" ]
                                        ]
                                    , Layout.sectionList []
                                        (settings.parameters
                                            |> List.indexedMap
                                                (\index ->
                                                    \setting ->
                                                        Layout.listItems []
                                                            [ Html.text ("Attemps : " ++ String.fromInt (index + 1))
                                                            , Html.div [ Attrs.class "flex gap-2" ]
                                                                [ input []
                                                                    [ Input.label [] [ Html.text "Minimum Rating" ]
                                                                    , Input.inputNumber
                                                                        [ Attrs.step "0.5"
                                                                        , Attrs.value (Stars.toString setting.minRating)
                                                                        , Events.onInput (SetSettingMinRating settings setting index)
                                                                        ]
                                                                        []
                                                                    ]
                                                                , input []
                                                                    [ Input.label [] [ Html.text "Number person per room" ]
                                                                    , Input.inputNumber
                                                                        [ Attrs.step "2"
                                                                        , Attrs.value (OneToTen.toString setting.personPerRoom)
                                                                        , Events.onInput (SetSettingPersonPerRoom settings setting index)
                                                                        ]
                                                                        []
                                                                    ]
                                                                , input []
                                                                    [ Input.label [] [ Html.text "Percentage of budget used for accomadation" ]
                                                                    , Input.inputNumber
                                                                        [ Attrs.step "5"
                                                                        , Attrs.value (Percentage.toString setting.percentageOfBudgetForHotel)
                                                                        , Events.onInput (SetSettingPercentageForHotel settings setting index)
                                                                        ]
                                                                        []
                                                                    ]
                                                                ]
                                                            ]
                                                )
                                        )
                                    ]
                        ]
                    ]

            Route.Callback _ ->
                Html.text ""

            Route.Logout ->
                Html.text ""
        ]
    }


inquiryPage : Model -> CustomerId -> InquiryId -> Html.Html Msg
inquiryPage model customerId inquiryId =
    Layout.layout []
        [ Layout.detail []
            (case model.requestedPackage of
                Loading ->
                    [ Layout.section [] [ Layout.sectionContent [] [ loader ] ] ]

                NotAsked ->
                    [ Layout.section [] [ Layout.sectionContent [] [ loader ] ] ]

                Failure e ->
                    [ Layout.section [] [ Layout.sectionContent [] [ httpErrorToHtml e ] ] ]

                Success package ->
                    [ Layout.nav [] [ Layout.back [] [ Html.a [ Attrs.class "text-2xl pr-10", Attrs.href <| (Route.toUrl <| Route.Customer customerId) ] [ Html.text "Return Customer" ] ] ]
                    , Layout.title [] [ Html.text "Quotes" ]
                    , quoteCustomerInformationSection package
                    , quoteTravelInformationSection package
                    , Layout.section []
                        [ Layout.sectionTitle [] [ Html.text "Logs" ]
                        , Layout.sectionContent []
                            [ package.logs
                                |> List.map
                                    (\l ->
                                        UI.Log.log [] [ Html.text (l.created ++ " - " ++ writtenByToStr l.writtenBy ++ " | " ++ l.msg) ]
                                    )
                                |> UI.Log.logs []
                            ]
                        ]
                    , Layout.section [ Attrs.class "" ]
                        [ Layout.sectionList []
                            [ Layout.listTitle []
                                [ Html.h3 [ Attrs.class "p-4" ] [ Html.text "Our Propositions" ]
                                , Html.a [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId inquiryId (ProposalId <| List.length package.proposals) Route.Proposed Route.None)), Attrs.class "pb-4 pr-4 text-6xl " ] [ Html.text "+" ]
                                ]
                            , Layout.listItems []
                                (package.proposals
                                    |> List.indexedMap
                                        (\index _ ->
                                            Html.li [ Attrs.class "flex justify-between items-center borber-b border-blue-300" ]
                                                [ Html.span [] [ Html.text ("Proposal #" ++ String.fromInt (index + 1)) ]
                                                , Html.div []
                                                    [ Html.a [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId inquiryId (ProposalId index) Route.Proposed Route.None)), Attrs.class "p-2" ] [ Html.text "✎" ]
                                                    ]
                                                ]
                                        )
                                )
                            ]
                        ]
                    ]
            )
        ]


bannersPage : Model -> Html.Html Msg
bannersPage model =
    layout []
        [ Layout.table []
            [ Html.div []
                [ UI.button [] [ Html.a [ Attrs.href <| Route.toUrl Route.NewBanners ] [ Html.text "Add Banner" ] ]
                ]
            , Html.div
                [ Attrs.class "relative"
                ]
                [ Html.table
                    [ Attrs.class "w-full text-3xl lg:text-sm text-left text-gray-500 dark:text-gray-400 max-w-full"
                    ]
                    [ Html.thead
                        [ Attrs.class "text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400l"
                        ]
                        [ Html.tr []
                            [ Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Name" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Image" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                []
                            ]
                        ]
                    , Html.tbody [] <|
                        (model.banners
                            |> RemoteData.withDefault []
                            |> List.map
                                (\banner ->
                                    Html.tr
                                        [ Attrs.class "bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                        ]
                                        [ Html.th
                                            [ Attrs.scope "row"
                                            , Attrs.class "px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            ]
                                            [ Html.text banner.name ]
                                        , Html.td
                                            [ Attrs.class "px-2 py-4"
                                            ]
                                            [ Html.div [ Attrs.class "h-96 w-full" ] [ Html.img [ Attrs.class "object-contain h-full w-full", Attrs.src banner.image.url ] [] ] ]
                                        , Html.td
                                            [ Attrs.class "px-2 py-4"
                                            ]
                                            [ Layout.tableButton [ Events.onClick (RemoveBanner banner.id) ] [ Html.text "🗑" ] ]
                                        ]
                                )
                        )
                    ]
                ]
            ]
        ]


inquiryProposalPage : Model -> CustomerId -> InquiryId -> ProposalId -> Route.InquiryProposalRoute -> Route.Hash -> Html.Html Msg
inquiryProposalPage model customerId (InquiryId inquiryId) (ProposalId version) inquiryRoute hash =
    Layout.layout []
        [ Layout.detail []
            (case model.requestedPackage of
                Loading ->
                    [ Layout.section [] [ Layout.sectionContent [] [ loader ] ] ]

                NotAsked ->
                    [ Layout.section [] [ Layout.sectionContent [] [ loader ] ] ]

                Failure e ->
                    [ Layout.section [] [ Layout.sectionContent [] [ httpErrorToHtml e ] ] ]

                Success request ->
                    let
                        currentProposal =
                            request.proposals |> List.getAt version |> Maybe.withDefault (defaultProposal request.destination)
                    in
                    [ Layout.nav []
                        [ Layout.back [ Attrs.href (Route.toUrl <| Route.Inquiry customerId (InquiryId inquiryId)) ] [ Html.text "Return Quotes" ]
                        , Layout.navBtn []
                            [ UI.button
                                [ Events.onClick (SaveProposal request)
                                , Attrs.class "bg-blue-300 p-2 "
                                ]
                                [ Html.text "Save" ]
                            ]
                        ]
                    , quoteTravelInformationSection request
                    , Layout.section [ Attrs.class "" ]
                        [ Layout.sectionTitle []
                            [ Html.text ("Proposal #" ++ String.fromInt (version + 1))
                            , Html.a
                                [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) inquiryRoute Route.SendEmailHash))
                                , Events.onClick (SaveProposal request)
                                ]
                                [ Html.text "📧" ]
                            ]
                        , Layout.sectionContent []
                            [ input [] [ Input.radio [ Attrs.name "currency", Events.onInput (\_ -> SetCurrency request (ProposalId version) "USD"), Attrs.checked (currentProposal.currency == "USD") ] [ Html.text "USD" ], Input.radio [ Attrs.name "currency", Events.onInput (\_ -> SetCurrency request (ProposalId version) "CAD"), Attrs.checked (currentProposal.currency == "CAD") ] [ Html.text "CAD" ] ]
                            , input []
                                [ Input.label [] [ Html.text "Introduction" ]
                                , Input.textarea [ Attrs.value currentProposal.introduction, Events.onInput (SetIntroduction request (ProposalId version)) ] []
                                ]
                            , Html.a
                                [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.SelectBanner Route.None)) ]
                                [ UI.button [ Attrs.class "bg-red-300" ] [ Html.text "Select Banner" ] ]
                            , Html.img [ Attrs.src currentProposal.banner ] []
                            ]
                        , Layout.sectionList []
                            [ Layout.listTitle []
                                [ Html.h2 [ Events.onClick (ToggleSection "Hotels"), Attrs.class "py-4 pl-4 cursor-pointer" ] [ Html.text <| "Option - " ++ (String.fromInt <| List.length currentProposal.hotels) ]
                                , Html.div [ Attrs.class "flex gap-2 pr-2" ]
                                    [ UI.button [ Attrs.class "bg-orange-300", Events.onClick (AddHotelProposal (InquiryId inquiryId) (ProposalId version)) ] [ Html.text "Random" ]
                                    , Html.a
                                        [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) (Route.ProposedHotel (List.length currentProposal.hotels) Route.None) Route.None))
                                        ]
                                        [ UI.button [ Attrs.class "bg-red-300" ] [ Html.text "Manual" ] ]
                                    ]
                                ]
                            , Layout.listItems [ Attrs.classList [ ( "hidden", model.toggledSection |> List.any ((==) "Hotels") ) ] ] <|
                                (currentProposal.hotels
                                    |> List.indexedMap
                                        (\index hotel ->
                                            Html.div
                                                [ Attrs.class "border border-blue-300 border-solid " ]
                                                [ Html.h3 [ Attrs.class "py-4 pl-4 bg-blue-300 [&>a]:px-4 " ]
                                                    [ Html.text <| ("Option " ++ String.fromInt (index + 1) ++ " - " ++ hotel.name)
                                                    , Html.a [ Attrs.href <| Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) (Route.ProposedHotel index Route.None) Route.None) ] [ Html.text "✎" ]
                                                    , Html.a [ Attrs.href <| Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) (Route.ProposedHotel index Route.None) Route.DeleteHash) ] [ Html.text "🗑" ]
                                                    ]
                                                , Html.ul [ Attrs.class "pl-4" ]
                                                    (Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Property name : " ], Html.text hotel.name ]
                                                        :: (case hotel.description of
                                                                AsString str ->
                                                                    [ Html.li [] [ Html.text "Description :", Html.text str ] ]

                                                                DetailDescription des ->
                                                                    [ Html.li [] [ Html.text des.bed ]
                                                                    , Html.li [] [ Html.text des.address ]
                                                                    , Html.li [] [ Html.text des.stars ]
                                                                    , Html.li [] [ Html.text des.refundable ]
                                                                    ]

                                                                AllDetails des ->
                                                                    [ Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "URL : " ], Html.a [ Attrs.href des.hotelUrl ] [ Html.text des.hotelUrl ] ]
                                                                    , Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Bed : " ], Html.text des.bed ]
                                                                    , Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "address : " ], Html.text des.address ]
                                                                    , Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Guest Rating : " ], Html.text des.stars ]
                                                                    , Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Refundable date : " ], Html.text des.refundable ]
                                                                    , Html.li []
                                                                        [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Rentral Vehicle : " ]
                                                                        , Html.text des.car.name
                                                                        , Html.text " "
                                                                        , Html.text
                                                                            (if des.car.price > 0 then
                                                                                des.car.price |> String.fromFloat

                                                                             else
                                                                                ""
                                                                            )
                                                                        ]
                                                                    , Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Golf Course : " ], Html.text des.golfCourses ]
                                                                    ]
                                                           )
                                                        ++ [ Html.li [] [ Html.span [ Attrs.class "text-lg" ] [ Html.text "Total Price : " ], Html.text (hotel.price |> Maybe.map String.fromFloat |> Maybe.withDefault "") ] ]
                                                    )
                                                , Html.div [ Attrs.class "text-3xl grid grid-cols-1 lg:grid-cols-3" ] <|
                                                    (hotel.photos
                                                        |> List.map
                                                            (\s ->
                                                                Html.div
                                                                    [ Attrs.class "border relative"
                                                                    ]
                                                                    [ Html.input [ Attrs.type_ "checkbox", Attrs.id ("zoom" ++ s), Attrs.class "hidden peer" ] []
                                                                    , Html.label
                                                                        [ Attrs.for ("zoom" ++ s)
                                                                        , Attrs.class "block overflow-hidden peer-checked:z-50 peer-checked:overflow-visible peer-checked:fixed peer-checked:max-h-screen peer-checked:h-screen peer-checked:w-screen peer-checked:left-0 peer-checked:top-0 peer-checked:flex peer-checked:justify-center peer-checked:items-center"
                                                                        ]
                                                                        [ Html.img [ Attrs.class "object-fit", Attrs.src s ] [] ]
                                                                    , UI.button [ Events.onClick (RemoveImage request (ProposalId version) index s) ] [ Html.text "🗑" ]
                                                                    ]
                                                            )
                                                    )
                                                ]
                                        )
                                )
                            ]
                        , Layout.sectionList []
                            [ Layout.listTitle [ Events.onClick (ToggleSection "golf-course") ]
                                [ Html.h2 [ Attrs.class "p-4" ] [ Html.text <| "Golf Course  - " ++ (String.fromInt <| List.length currentProposal.golfCourses) ]
                                , Html.a
                                    [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) (Route.ProposedGolfCourse (List.length currentProposal.golfCourses) Route.None) Route.None))
                                    , Attrs.class "pb-4 pr-4 text-6xl "
                                    ]
                                    [ Html.text "+" ]
                                ]
                            ]
                        ]
                    , Layout.section []
                        [ Layout.sectionContent []
                            [ case inquiryRoute of
                                Route.Proposed ->
                                    Html.text ""

                                Route.SelectBanner ->
                                    modal { show = True, closeUrl = Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None) }
                                        []
                                        [ Html.div [ Attrs.class "[&>h4]:block text-4xl [&>button]:w-auto  [&>button]:m-2 hover:[&>button]:opacity-50" ]
                                            [ model.banners
                                                |> RemoteData.withDefault []
                                                |> List.map
                                                    (\b ->
                                                        Html.li []
                                                            [ Html.span [] [ Html.text b.name ]
                                                            , Html.img [ Attrs.src b.image.url ] []
                                                            , UI.button [ Events.onClick (SelectBanner request (ProposalId version) b) ] [ Html.text "Select Banner" ]
                                                            ]
                                                    )
                                                |> Html.ul []
                                            ]
                                        ]

                                Route.ProposedHotel entityId hash2 ->
                                    if hash2 == Route.DeleteHash then
                                        modal { show = True, closeUrl = Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None) }
                                            []
                                            [ Html.div [ Attrs.class "[&>h4]:block text-4xl [&>button]:w-auto  [&>button]:m-2 hover:[&>button]:opacity-50" ]
                                                [ Html.h4 [] [ Html.text "Are you sure the delete this Hotel ?" ]
                                                , UI.button [ Attrs.class "bg-red-300 px-20 py-8 lg:px-5 lg:py-2", Events.onClick (DeleteHotel (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None) request (ProposalId version) version entityId) ] [ Html.text "Yes" ]
                                                , UI.button [ Attrs.class "bg-green-300 [&_a]:block px-20 py-8 lg:px-5 lg:py-2" ] [ Html.a [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None)) ] [ Html.text "No" ] ]
                                                ]
                                            ]

                                    else
                                        customerProposalModal (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None) version entityId request currentProposal model

                                Route.ProposedGolfCourse entityId hash2 ->
                                    if hash2 == Route.DeleteHash then
                                        modal { show = True, closeUrl = Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None) }
                                            []
                                            [ Html.div [ Attrs.class "[&>h4]:block [&>button]:w-auto [&>button]:m-2 hover:[&>button]:opacity-50" ]
                                                [ Html.h4 [] [ Html.text "Are you sure the delete this Golf Course ?" ]
                                                , UI.button [ Attrs.class "bg-red-300 px-20 py-8 lg:px-5 lg:py-2", Events.onClick (DeleteGolfCourse (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None) request (ProposalId version) version entityId) ] [ Html.text "Yes" ]
                                                , UI.button [ Attrs.class "bg-green-300 [&_a]:block px-20 py-8 lg:px-5 lg:py-2" ] [ Html.a [ Attrs.href (Route.toUrl (Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None)) ] [ Html.text "No" ] ]
                                                ]
                                            ]

                                    else
                                        Html.div [] []
                            , modal { show = hash == Route.SendEmailHash, closeUrl = Route.toUrl <| Route.InquiryProposal customerId (InquiryId inquiryId) (ProposalId version) Route.Proposed Route.None }
                                []
                                [ Html.div [ Attrs.class "" ]
                                    [ (case model.emailPreview of
                                        Success preview ->
                                            -- case Html.Parser.run preview of
                                            --     Ok nodes ->
                                            [ Html.node "inner-html" [ Attrs.attribute "content" preview ] [] ]

                                        -- Err err ->
                                        --     err
                                        --         |> List.map
                                        --             (\{ col, row, problem } ->
                                        --                 Html.text <| "col: " ++ String.fromInt col ++ " row: " ++ String.fromInt row ++ " " ++ Html.Parser.errorToString problem ++ " is " ++ String.slice col (String.length preview) preview
                                        --             )
                                        _ ->
                                            [ loader ]
                                      )
                                        |> Html.div []
                                    , case model.errors of
                                        [] ->
                                            Html.text ""

                                        errors ->
                                            Html.div [ Attrs.class "bg-yellow-100 p-2 text-red-300 border-red-300 border-2" ]
                                                [ Html.p [] [ Html.text "All errors bellow prevent the email to be sent" ]
                                                , Html.ul [ Attrs.class "text-sm list-disc pl-5" ] (errors |> List.map (\s -> Html.li [] [ Html.text s ]))
                                                ]
                                    , Html.h4 [] [ Html.text <| "Send Proposition - " ++ String.fromInt version ]
                                    , input [ Attrs.class "py-4" ]
                                        [ Input.label [ Attrs.for "Email" ] [ Html.text "Email" ]
                                        , Input.textInput [ Attrs.id "Email", Attrs.placeholder "Email", Attrs.value model.email, Events.onInput SetEmail ] [ Html.text model.email ]
                                        ]
                                    , Html.span [ Attrs.class "text-lg font-black" ] [ Html.text "Other emails" ]
                                    , Html.div
                                        [ Attrs.class "text-lg" ]
                                        (request.friendEmails
                                            |> List.map
                                                (\e ->
                                                    Html.text e
                                                )
                                        )
                                    , UI.button [ Events.onClick (SendTo model.email request version) ] [ Html.span [] [ Html.text "Send" ] ]
                                    ]
                                ]
                            ]
                        ]
                    ]
            )
        ]


customerListPage : Model -> Html.Html Msg
customerListPage model =
    layout []
        [ Layout.table [ Attrs.class "lg:w-1/3" ]
            [ Html.div []
                [ input []
                    [ Input.label [ Attrs.for "Email" ] [ Html.text "Email" ]
                    , Input.textInput [ Attrs.id "Email", Attrs.placeholder "Email", Events.onInput SetEmailFilter ] [ Html.text <| (model.emailFilter |> Maybe.withDefault "") ]
                    ]
                , UI.button [ Attrs.class "text-3xl lg:text-sm mt-4 p-2 w-full rounded bg-blue-300 hover:bg-blue-400", Events.onClick SearchCustomer ] [ Html.text "Search" ]
                ]
            , Html.div
                [ Attrs.class "relative"
                ]
                [ Html.table
                    [ Attrs.class "w-full text-3xl lg:text-sm text-left text-gray-500 dark:text-gray-400 max-w-full"
                    ]
                    [ Html.thead
                        [ Attrs.class "text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400l"
                        ]
                        [ Html.tr []
                            [ Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "FirstName" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "LastName" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Email" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                []
                            ]
                        ]
                    , Html.tbody [] <|
                        case model.customers of
                            Loading ->
                                [ loader ]

                            NotAsked ->
                                [ loader ]

                            Failure e ->
                                [ httpErrorToHtml e ]

                            Success customers ->
                                customers
                                    |> List.map
                                        (\customer ->
                                            Html.tr
                                                [ Attrs.class "bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                                ]
                                                [ Html.th
                                                    [ Attrs.scope "row"
                                                    , Attrs.class "px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    ]
                                                    [ Html.text customer.firstName ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Html.text customer.lastName ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Html.text customer.email ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Layout.tableButton [] [ Html.a [ Attrs.href <| Route.toUrl (Route.Customer (CustomerId customer.id)) ] [ Html.text "✎" ] ] ]
                                                ]
                                        )
                    ]
                ]
            ]
        ]


customerPage : Model -> Html.Html Msg
customerPage model =
    Layout.layout []
        [ Layout.detail []
            (case model.customer of
                Loading ->
                    [ Layout.section [] [ Layout.sectionContent [] [ loader ] ] ]

                NotAsked ->
                    [ Layout.section [] [ Layout.sectionContent [] [ loader ] ] ]

                Failure err ->
                    [ Layout.section [] [ Layout.sectionContent [] [ httpErrorToHtml err ] ] ]

                Success customer ->
                    [ Layout.nav []
                        [ Layout.back [] [ Html.a [ Attrs.class "text-2xl pr-10", Attrs.href <| (Route.toUrl <| Route.Customers) ] [ Html.text "Return Customers list" ] ]
                        , Layout.navBtn []
                            [ UI.button
                                [ Events.onClick (SaveCustomer customer)
                                , Attrs.class "bg-blue-300 p-2 "
                                ]
                                [ Html.text "Save" ]
                            ]
                        ]
                    , Layout.title [] [ Html.text "Customer" ]
                    , Layout.section [ Attrs.class "" ]
                        [ Layout.sectionTitle [ Attrs.class "text-5xl pb-10" ] [ Html.text "Customer Information" ]
                        , Layout.sectionContent [ Attrs.class "grid grid-cols-2 lg:grid-cols-4 gap-4  " ]
                            [ input []
                                [ Input.label [ Attrs.for "FirstName" ] [ Html.text "FirstName" ]
                                , Input.textInput [ Attrs.id "FirstName", Attrs.placeholder "FirstName", Attrs.value customer.firstName, Attrs.disabled True ] [ Html.text customer.firstName ]
                                ]
                            , input []
                                [ Input.label [ Attrs.for "LastName" ] [ Html.text "LastName" ]
                                , Input.textInput [ Attrs.id "LastName", Attrs.placeholder "LastName", Attrs.value customer.lastName, Attrs.disabled True ] [ Html.text customer.lastName ]
                                ]
                            , input []
                                [ Input.label [ Attrs.for "Phone" ] [ Html.text "Phone" ]
                                , Input.textInput [ Attrs.id "Phone", Attrs.placeholder "Phone", Attrs.value customer.phoneNumber, Events.onInput (SetCustomerPhone customer) ] [ Html.text customer.phoneNumber ]
                                ]
                            , input []
                                [ Input.label [ Attrs.for "Email" ] [ Html.text "Email" ]
                                , Input.textInput [ Attrs.id "Email", Attrs.placeholder "Email", Attrs.value customer.email, Events.onInput (SetCustomerEmail customer) ] [ Html.text customer.email ]
                                ]
                            ]
                        ]
                    , Layout.section []
                        [ Layout.sectionTitle [] [ Html.text "Logs" ]
                        , Layout.sectionContent []
                            [ customer.logs
                                |> List.map
                                    (\l ->
                                        UI.Log.log [] [ Html.text (l.created ++ " - " ++ writtenByToStr l.writtenBy ++ " | " ++ l.msg) ]
                                    )
                                |> UI.Log.logs []
                            ]
                        ]
                    , Layout.section []
                        [ Layout.sectionTitle [] [ Html.text "Notes" ]
                        , Layout.sectionContent []
                            [ input [] [ Input.textarea [ Attrs.value customer.notes, Events.onInput (SetCustomerNote customer) ] [] ]
                            , UI.button
                                [ Events.onClick (SaveCustomer customer)
                                , Attrs.class "bg-blue-300 p-2 "
                                ]
                                [ Html.text "Save" ]
                            ]
                        ]
                    , Layout.section []
                        (Layout.sectionTitle [ Attrs.class "text-5xl pb-10" ] [ Html.text "Requested Packages" ]
                            :: (case model.requestedPackages of
                                    Loading ->
                                        [ Layout.sectionContent [] [ loader ] ]

                                    NotAsked ->
                                        [ Layout.sectionContent [] [ loader ] ]

                                    Failure e ->
                                        [ Layout.sectionContent [] [ httpErrorToHtml e ] ]

                                    Success packages ->
                                        [ Layout.sectionContent []
                                            [ Html.table
                                                [ Attrs.class "w-full text-3xl lg:text-sm text-left text-gray-500 dark:text-gray-400 max-w-full"
                                                ]
                                                [ Html.thead
                                                    [ Attrs.class "text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400l"
                                                    ]
                                                    [ Html.tr []
                                                        [ Html.th
                                                            [ Attrs.scope "col"
                                                            , Attrs.class "px-2 py-3"
                                                            ]
                                                            [ Html.text "Created" ]
                                                        , Html.th
                                                            [ Attrs.scope "col"
                                                            , Attrs.class "px-2 py-3"
                                                            ]
                                                            [ Html.text "Destination" ]
                                                        , Html.th
                                                            [ Attrs.scope "col"
                                                            , Attrs.class "px-2 py-3"
                                                            ]
                                                            [ Html.text "Departure Date" ]
                                                        , Html.th
                                                            [ Attrs.scope "col"
                                                            , Attrs.class "px-2 py-3"
                                                            ]
                                                            [ Html.text "Return Date" ]
                                                        , Html.th
                                                            [ Attrs.scope "col"
                                                            , Attrs.class "px-2 py-3"
                                                            ]
                                                            []
                                                        ]
                                                    ]
                                                , packages
                                                    |> List.map
                                                        (\p ->
                                                            Html.tr
                                                                [ Attrs.class "bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                                                ]
                                                                [ Html.th
                                                                    [ Attrs.scope "row"
                                                                    , Attrs.class "px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                                    ]
                                                                    [ Html.text (toIsoDate Time.utc p.createdAt) ]
                                                                , Html.td
                                                                    [ Attrs.class "px-2 py-4"
                                                                    ]
                                                                    [ Html.text p.destination ]
                                                                , Html.td
                                                                    [ Attrs.class "px-2 py-4"
                                                                    ]
                                                                    [ Html.text p.departureDate ]
                                                                , Html.td
                                                                    [ Attrs.class "px-2 py-4"
                                                                    ]
                                                                    [ Html.text p.returnDate ]
                                                                , Html.td
                                                                    [ Attrs.class "px-2 py-4"
                                                                    ]
                                                                    [ Layout.tableButton [] [ Html.a [ Attrs.href (Route.toUrl <| Route.Inquiry (CustomerId customer.id) (InquiryId p.id)), Attrs.class "p-2" ] [ Html.text "✎" ] ] ]
                                                                ]
                                                        )
                                                    |> Html.tbody []
                                                ]
                                            ]
                                        ]
                               )
                        )
                    ]
            )
        ]


customerProposalModal : Route.Route -> Int -> Int -> RequestPackage -> Proposal -> Model -> Html.Html Msg
customerProposalModal route version hotelId request proposal model =
    modal { show = True, closeUrl = Route.toUrl route }
        []
        [ searchDropdown [ Attrs.class " text-3xl" ]
            [ UI.SearchDropdown.input []
                [ Input.label [ Attrs.for "search-hotel" ] [ Html.text "Search for Hotel" ]
                , Input.textInput [ Events.onInput SetHotelFilter, Attrs.id "search-hotel", Attrs.placeholder "Search for Hotel Name", Attrs.value model.hotelName ] []
                ]
            , UI.SearchDropdown.dropdown [ Attrs.classList [ ( "hidden", not (model.dropdownToShow == Just "hotels") ) ] ] <|
                case model.hotels of
                    Loading ->
                        [ UI.SearchDropdown.dropdownItem [] [ Html.text "Loading" ] ]

                    Success _ ->
                        model.hotelsFiltered |> List.map (\h -> UI.SearchDropdown.dropdownItem [ Events.onClick (LoadHotel request proposal h), Attrs.class "shadow-slate-100" ] [ Html.text h.propertyName ])

                    NotAsked ->
                        []

                    Failure e ->
                        [ UI.SearchDropdown.dropdownItem [] [ httpErrorToHtml e ] ]
            ]
        , case model.hotel of
            NotAsked ->
                Html.div []
                    [ Html.text "You must search for hotel", golfPart model ]

            Loading ->
                loader

            Failure e ->
                httpErrorToHtml e

            Success ho ->
                Html.div []
                    [ ho.roomTypes
                        |> List.filter (\x -> (x.bedTypeOptions |> List.length) >= 1)
                        |> List.indexedMap
                            (\index room ->
                                Html.li [ Attrs.class "flex flex-col border w-96" ]
                                    [ Html.img [ Attrs.class "w-full h-auto", Attrs.src (room.media |> List.head |> Maybe.map .url |> Maybe.withDefault "") ] []
                                    , Html.h2 [ Attrs.class "text-2xl" ] [ Html.text room.description ]
                                    , room.bedTypeOptions
                                        |> List.map
                                            (\o ->
                                                [ Html.li
                                                    [ Attrs.class "text-lg" ]
                                                    [ Html.text o.description ]
                                                , Html.li [ Attrs.class "text-lg" ]
                                                    [ Html.text (room.ratePlans |> List.map (\p -> p.cancellationPolicy.freeCancellationEndDateTime |> Maybe.map (\d -> "Refundable before " ++ toIsoDate Time.utc d) |> Maybe.withDefault "No Refundable") |> String.join ",") ]
                                                ]
                                            )
                                        |> List.concat
                                        |> Html.ul []
                                    , Html.span [] [ Html.text "Price : ", Html.text room.price.totalPriceWithHotelFees.value, Html.text " ", Html.text room.price.totalPriceWithHotelFees.currency ]
                                    , input []
                                        [ Input.label [ Attrs.for ("numberOfRoom" ++ room.description) ]
                                            [ Html.text "Number of room" ]
                                        , Input.inputNumber
                                            [ Attrs.id ("numberOfRoom" ++ room.description), Events.onInput (SetRoom request ho index room) ]
                                            []
                                        ]
                                    ]
                            )
                        |> Html.ul [ Attrs.class "grid grid-cols-1 md:grid-cols-2 gap-4 pt-8" ]
                    , Html.a [ Attrs.class "text-xs", Attrs.href ho.links.webSearchResult.href ] [ Html.text ho.links.webSearchResult.href ]
                    , input [ Attrs.class "" ]
                        [ Input.label [ Attrs.for "Destination" ] [ Html.text "Destination" ]
                        , Input.textInput [ Attrs.id "Destination", Attrs.placeholder "Destinatio", Attrs.value request.destination ] []
                        ]

                    -- , textInput
                    --     [ Attrs.class "" ]
                    --     [ Input.label [ Attrs.for "title" ] [ Html.text "Title" ]
                    --     , Input.textInput [ Events.onInput SetTitle, Attrs.id "title", Attrs.placeholder "Title", Attrs.value model.title ] []
                    --     ]
                    , input []
                        [ Input.label [ Attrs.for "bed" ] [ Html.text "Bed", tooltip [] [ UI.Tooltips.hover [] [] ] ]
                        , Input.textInput [ Events.onInput SetBed, Attrs.id "bed", Attrs.placeholder "Bed", Attrs.value model.bed ] []
                        ]
                    , input []
                        [ Input.label [ Attrs.for "address" ] [ Html.text "Address", tooltip [] [ UI.Tooltips.hover [] [] ] ]
                        , Input.textInput [ Events.onInput SetAddress, Attrs.id "address", Attrs.placeholder "Address", Attrs.value model.address ] []
                        ]
                    , input []
                        [ Input.label [ Attrs.for "refundable" ] [ Html.text "Refundable", tooltip [] [ UI.Tooltips.hover [] [] ] ]
                        , Input.textInput [ Events.onInput SetRefundable, Attrs.id "refundable", Attrs.placeholder "Refundable", Attrs.value model.refundable ] []
                        ]
                    , input []
                        [ Input.label [ Attrs.for "stars" ] [ Html.text "Stars", tooltip [] [ UI.Tooltips.hover [] [] ] ]
                        , Input.textInput [ Events.onInput SetStars, Attrs.id "stars", Attrs.placeholder "Stars", Attrs.value model.stars ] []
                        ]
                    , input [ Attrs.class "" ]
                        [ Input.label [ Attrs.for "hotel-Price" ] [ Html.text "Hotel Price" ]
                        , Input.inputNumber [ Events.onInput SetHotelPrice, Attrs.id "hotel-Price", Attrs.placeholder "Hotel Price", Attrs.value model.hotelPrice ] []
                        ]
                    , input [ Attrs.class "" ]
                        [ Input.label [ Attrs.for "free" ] [ Html.text "Fee" ]
                        , Input.inputNumber [ Events.onInput SetFee, Attrs.id "fee", Attrs.placeholder "Fee", Attrs.value model.fee ] []
                        ]
                    , input [ Attrs.class "" ]
                        [ Input.label [ Attrs.for "carPrice" ] [ Html.text "Car Price" ]
                        , Input.inputNumber [ Events.onInput SetCarPrice, Attrs.id "carPrice", Attrs.placeholder "Car Price", Attrs.value model.carPrice ] []
                        ]
                    , golfPart model
                    , Html.div []
                        [ Html.h3 [] [ Html.text "Select Photos" ]
                        , GaleryPhoto.galeryPhoto [] <|
                            (ho.media
                                |> List.map
                                    (\s ->
                                        Html.div
                                            [ Attrs.class "border"
                                            , Attrs.classList
                                                [ ( "border-blue-300 border-8", List.any ((==) (getPhotoId s.url)) (model.selectedImages |> List.map getPhotoId) )
                                                ]
                                            , Events.onClick (SetImages s.url)
                                            ]
                                            [ Html.img [ Attrs.src s.url ] []
                                            ]
                                    )
                            )
                        ]
                    ]
        , summary model request
        , Html.div [ Attrs.class "p-4" ]
            [ UI.button
                [ Events.onClick (AddNewProposal route request (ProposalId version) version hotelId)
                , Attrs.class "bg-blue-300 p-4 "
                ]
                [ Html.text "Confirm" ]
            ]
        ]


summary : Model -> RequestPackage -> Html.Html Msg
summary model request =
    Html.div []
        [ Html.div [] [ Html.text "Total Hotel Price : ", Html.text model.hotelPrice ]
        , Html.div [] [ Html.text "Total Fee : ", Html.text ((model.fee |> String.toFloat |> Maybe.withDefault 0.0) * (request.numberOfTraveller |> String.toFloat |> Maybe.withDefault 1.0) |> String.fromFloat) ]
        , Html.div [] [ Html.text "Total Car Price : ", Html.text model.carPrice ]
        , Html.div [] [ Html.text "Total Golf course price : ", Html.text (model.golfCoursesBuilder |> List.map (.price >> String.toInt >> Maybe.withDefault 0) |> List.sum |> (*) (request.numberOfTraveller |> String.toInt |> Maybe.withDefault 1) |> String.fromInt) ]
        , Html.div [] [ Html.text "Total Price : ", Html.text model.price ]
        , Html.div [] [ Html.text "Price per person : ", Html.text ((model.price |> String.toFloat |> Maybe.withDefault 0.0) / (request.numberOfTraveller |> String.toFloat |> Maybe.withDefault 1.0) |> String.fromFloat) ]
        ]


golfPart : Model -> Html.Html Msg
golfPart model =
    Html.div [] <|
        List.initialize 10
            (\i ->
                Html.div [ Attrs.class "flex" ]
                    [ input []
                        [ Input.label
                            [ Attrs.for ("golf-courses-name" ++ String.fromInt i)
                            ]
                            [ Html.text ("Golf Courses Name " ++ String.fromInt i), tooltip [] [ UI.Tooltips.hover [] [] ] ]
                        , Input.textInput
                            [ Events.onInput (SetGolfCourseNameById i)
                            , Attrs.id ("golf-courses" ++ String.fromInt i)
                            , Attrs.placeholder "Golf Courses Name"
                            , Attrs.value (List.getAt i model.golfCoursesBuilder |> Maybe.map .name |> Maybe.withDefault "")
                            ]
                            []
                        ]
                    , input []
                        [ Input.label
                            [ Attrs.for ("golf-courses-price" ++ String.fromInt i)
                            ]
                            [ Html.text ("Golf Courses Price Per Person " ++ String.fromInt i), tooltip [] [ UI.Tooltips.hover [] [] ] ]
                        , Input.textInput
                            [ Events.onInput (SetGolfCoursePrice i)
                            , Attrs.id ("golf-courses-price" ++ String.fromInt i)
                            , Attrs.placeholder "Golf Courses Price Per Person"
                            , Attrs.value (List.getAt i model.golfCoursesBuilder |> Maybe.map .price |> Maybe.withDefault "")
                            ]
                            []
                        ]
                    ]
            )


getPhotoId : String -> Maybe String
getPhotoId =
    String.split "/" >> List.reverse >> List.head


quoteCustomerInformationSection : RequestPackage -> Layout.Detail Msg
quoteCustomerInformationSection package =
    Layout.section [ Attrs.class "" ]
        [ Layout.sectionTitle [ Attrs.class "text-5xl pb-10" ] [ Html.text "Customer Information" ]
        , Layout.sectionContent [ Attrs.class "grid grid-cols-2 lg:grid-cols-4 gap-4  " ]
            [ input []
                [ Input.label [ Attrs.for "FirstName" ] [ Html.text "FirstName" ]
                , Input.textInput [ Attrs.id "FirstName", Attrs.placeholder "FirstName", Attrs.value package.firstName ] []
                ]
            , input []
                [ Input.label [ Attrs.for "LastName" ] [ Html.text "LastName" ]
                , Input.textInput [ Attrs.id "LastName", Attrs.placeholder "LastName", Attrs.value package.lastName ] []
                ]
            , input []
                [ Input.label [ Attrs.for "Phone" ] [ Html.text "Phone" ]
                , Input.textInput [ Attrs.id "Phone", Attrs.placeholder "Phone", Attrs.value package.phoneNumber ] []
                ]
            , input []
                [ Input.label [ Attrs.for "Email" ] [ Html.text "Email" ]
                , Input.textInput [ Attrs.id "Email", Attrs.placeholder "Email", Attrs.value package.email ] []
                ]
            ]
        ]


quoteTravelInformationSection : RequestPackage -> Layout.Detail Msg
quoteTravelInformationSection package =
    Layout.section [ Attrs.class "" ]
        [ Layout.sectionTitle [] [ Html.text "Travel Information" ]
        , Layout.sectionContent [ Attrs.class "grid grid-cols-2 lg:grid-cols-4 gap-4  " ]
            [ input []
                [ Input.label [ Attrs.for "Destination" ] [ Html.text "Destination" ]
                , Input.textInput [ Attrs.id "Destination", Attrs.placeholder "Destination", Attrs.value package.destination, Attrs.disabled True ] []
                ]
            , input []
                [ Input.label [ Attrs.for "DepartFrom" ] [ Html.text "Depart From" ]
                , Input.textInput [ Attrs.id "DepartFrom", Attrs.placeholder "Depart From", Attrs.value package.departFrom ] []
                ]
            , input []
                [ Input.label [ Attrs.for "DepartureDate" ] [ Html.text "Departure Date" ]
                , Input.calendar [ Attrs.id "DepartureDate", Attrs.placeholder "Departure Date", Attrs.value package.departureDate, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetDepartureDate s)) ] []
                ]
            , input []
                [ Input.label [ Attrs.for "ReturnDate" ] [ Html.text "Return Date" ]
                , Input.calendar [ Attrs.id "ReturnDate", Attrs.placeholder "Return Date", Attrs.value package.returnDate, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetReturnDate s)) ] []
                ]
            , input []
                [ Input.label [ Attrs.for "numberOfTraveller" ] [ Html.text "numberOfTraveller" ]
                , Input.inputNumber [ Attrs.id "numberOfTraveller", Attrs.placeholder "numberOfTraveller", Attrs.value package.numberOfTraveller, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetNumberOfTraveller s)) ] []
                ]
            , input []
                [ Input.label [ Attrs.for "budgetPerPerson" ] [ Html.text "budgetPerPerson" ]
                , Input.textInput [ Attrs.id "budgetPerPerson", Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetBudgetPerPerson s)), Attrs.placeholder "budgetPerPerson", Attrs.value (String.fromInt package.budgetPerPerson) ] []
                ]
            , input []
                [ Input.label [ Attrs.for "numberOfRound" ] [ Html.text "numberOfRound" ]
                , Input.inputNumber [ Attrs.id "numberOfRound", Attrs.placeholder "numberOfRound", Attrs.value package.numberOfRound, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetNumberOfRound s)) ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "accomodationType" ] [ Html.text "accomodationType" ]
                , Input.textInput [ Attrs.id "accomodationType", Attrs.placeholder "accomodationType", Attrs.value (String.join ", " package.accomodationType), Attrs.disabled True ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "NumberOfRoom" ] [ Html.text "numberOfRoom" ]
                , Input.textInput [ Attrs.id "NumberOfRoom", Attrs.placeholder "numberOfRoom", Attrs.value (package.numberOfRoom |> Maybe.map String.fromInt |> Maybe.withDefault ""), Attrs.disabled True ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "NumberOfBed" ] [ Html.text "NumberOfBed" ]
                , Input.textInput [ Attrs.id "NumberOfBed", Attrs.placeholder "NumberOfBed", Attrs.value (package.numberOfBed |> Maybe.map String.fromInt |> Maybe.withDefault ""), Attrs.disabled True ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "numberOfStop" ] [ Html.text "numberOfStop" ]
                , Input.textInput [ Attrs.id "numberOfStop", Attrs.placeholder "numberOfStop", Attrs.value (String.join ", " package.numberOfStop), Attrs.disabled True ] []
                ]
            , input [ Attrs.class "col-span-4" ]
                [ Input.label [ Attrs.for "carType" ] [ Html.text "carType" ]
                , Input.textInput [ Attrs.id "carType", Attrs.placeholder "carType", Attrs.value (String.join ", " package.carType), Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetCarType s)) ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "sport" ] [ Html.text "sport" ]
                , Input.textInput [ Attrs.id "sport", Attrs.placeholder "sport", Attrs.value package.sport, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetSport s)) ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "team" ] [ Html.text "team" ]
                , Input.textInput [ Attrs.id "team", Attrs.placeholder "team", Attrs.value package.team, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetTeam s)) ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "seat" ] [ Html.text "seat" ]
                , Input.textInput [ Attrs.id "seat", Attrs.placeholder "seat", Attrs.value package.seat, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetSeat s)) ] []
                ]
            , input [ Attrs.class "col-span-2" ]
                [ Input.label [ Attrs.for "gameDate" ] [ Html.text "gameDate" ]
                , Input.textInput [ Attrs.id "gameDate", Attrs.placeholder "gameDate", Attrs.value package.gameDate, Events.onInput (\s -> SetRequestPackage (RequestedPackage.SetGameDate s)) ] []
                ]
            ]
        ]


filesDecoder : De.Decoder (List File)
filesDecoder =
    De.at [ "target", "files" ] (De.list File.decoder)


httpErrorToHtml : Http.Error -> Html.Html Msg
httpErrorToHtml err =
    case err of
        BadUrl str ->
            Html.text str

        Timeout ->
            Html.text "timeout"

        NetworkError ->
            Html.text "bad network"

        BadStatus status ->
            Html.text (String.fromInt status)

        BadBody body ->
            Html.text body


statuses : List String
statuses =
    [ "", "waiting-proposal", "proposal-sent", "1-reminder-sent", "2-reminder-sent", "3-reminder-sent", "prospect", "confirmed", "rejected", "completed", "no-response", "client-error" ]


requestView : { today : Posix, emailFilter : Maybe String, statusesFilter : List String } -> WebData (List RequestedPackage.RequestPackage) -> Html.Html Msg
requestView model requestedPackage =
    layout []
        [ Layout.table [ Attrs.class "lg:w-1/3" ]
            [ Html.div []
                [ -- input []
                  -- [ Input.label [ Attrs.for "Email" ] [ Html.text "Email" ]
                  -- , Input.textInput [ Attrs.id "Email", Attrs.placeholder "Email", Events.onInput SetEmailFilter ] [ Html.text <| (model.emailFilter |> Maybe.withDefault "") ]
                  -- ]
                  Html.div [ Attrs.class "flex w-full gap-2 pt-2" ] <|
                    (statuses
                        |> List.map
                            (\s ->
                                UI.button [ Attrs.class "shadow-xl rounded-xl p-2 lg:text-xs", Attrs.classList [ ( "opacity-50", model.statusesFilter |> List.any ((==) s) ) ], Events.onClick (SetStatusFilter s) ]
                                    [ Html.text
                                        (if s == "" then
                                            "none"

                                         else
                                            s
                                        )
                                    ]
                            )
                    )

                -- , UI.button [ Attrs.class "text-3xl lg:text-sm mt-4 p-2 w-full rounded bg-blue-300 hover:bg-blue-400", Events.onClick SearchCustomer ] [ Html.text "Search" ]
                ]
            , Html.div
                [ Attrs.class "relative  "
                ]
                [ Html.table
                    [ Attrs.class "w-full text-3xl lg:text-sm text-left text-gray-500 dark:text-gray-400 max-w-full bg-white"
                    ]
                    [ Html.thead
                        [ Attrs.class "text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 bg-white"
                        ]
                        [ Html.tr []
                            [ Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Name" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Email" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Destinations" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Created" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Quote Sent" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Last Reminder Sent" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                [ Html.text "Status" ]
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                []
                            , Html.th
                                [ Attrs.scope "col"
                                , Attrs.class "px-2 py-3"
                                ]
                                []
                            ]
                        ]
                    , Html.tbody [] <|
                        case requestedPackage of
                            Loading ->
                                [ loader ]

                            NotAsked ->
                                [ loader ]

                            Failure _ ->
                                [ Html.text "Error" ]

                            Success package ->
                                package
                                    |> List.map
                                        (\s ->
                                            Html.tr
                                                [ Attrs.class "bg-white border-b dark:bg-gray-800 dark:border-gray-700 [&>td]:bg-white [&>th]:bg-white"
                                                ]
                                                [ Html.th
                                                    [ Attrs.scope "row"
                                                    , Attrs.class "px-2 py-4 font-medium text-gray-900 whitespace-nowrap"
                                                    ]
                                                    [ Html.text <| s.lastName ++ ", " ++ s.firstName, Html.a [ Attrs.href ("/customers/" ++ s.customerId) ] [ Html.text " ↗" ] ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Html.text s.email ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Html.text s.destination ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Html.text <| timeAgo Time.utc s.createdAt model.today ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ s.quoteSent |> Maybe.map (\t -> timeAgo Time.utc t model.today) |> Maybe.withDefault "-" |> Html.text ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ s.reminders
                                                        |> List.sortBy
                                                            (\a ->
                                                                Time.toMillis Time.utc a.sent
                                                            )
                                                        |> List.head
                                                        |> Maybe.map
                                                            (\t ->
                                                                timeAgo Time.utc t.sent model.today
                                                            )
                                                        |> Maybe.withDefault "-"
                                                        |> Html.text
                                                    ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ UI.button [ Events.onClick (SendReminder s 0) ] [ Html.span [ Attrs.class "lg:text-xs text-black" ] [ Html.text "Send Reminder" ] ] ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ UI.Dropdown.dropdown []
                                                        [ UI.Dropdown.select [ Events.onInput (SetStatus s), Attrs.value s.status ]
                                                            (statuses |> List.map (\t -> UI.Dropdown.option [ Attrs.selected (t == s.status) ] [ Html.text t ]))
                                                        ]
                                                    ]
                                                , Html.td
                                                    [ Attrs.class "px-2 py-4"
                                                    ]
                                                    [ Layout.tableButton [] [ Html.a [ Attrs.href <| Route.toUrl (Route.Inquiry (CustomerId s.customerId) (InquiryId s.id)) ] [ Html.text "✎" ] ] ]
                                                ]
                                        )
                    ]
                ]
            ]
        ]


port auth0authResult : (Value -> msg) -> Sub msg


port auth0authorize : Auth0.Options -> Cmd msg


port auth0logout : Maybe String -> Cmd msg


port handleRedirectCallback : () -> Cmd msg


type alias Flags =
    { domain : String
    , user : Auth0.AuthenticationState
    , authentificationCallbackurl : String
    }


decodeFlags : De.Decoder Flags
decodeFlags =
    De.succeed Flags
        |> De.required "domain" De.string
        |> De.optional "user" Auth0.decodeAuthenticationState Auth0.LoggedOut
        |> De.optional "authentificationCallbackurl" De.string ""


defaultProposal : String -> Proposal
defaultProposal destination =
    { currency = "", banner = "", hotels = [], cars = [], golfCourses = [], flights = [], introduction = defaultIntroduction destination, conclusion = defaultConclusion, agentName = "", emailSent = Nothing }


defaultModel : Key -> Model
defaultModel key =
    { navigationKey = key
    , config =
        { domain = ""
        , user = Authentication.init auth0authorize auth0logout LoggedOut
        , authentificationCallbackurl = ""
        }
    , emailPreview = NotAsked
    , requestedPackages = NotAsked
    , requestedPackage = NotAsked
    , customer = NotAsked
    , customers = NotAsked
    , predictions = NotAsked
    , hotel = NotAsked
    , hotels = NotAsked
    , cars = NotAsked
    , images = NotAsked
    , debounce = Debounce.init
    , selectedHotel = Nothing
    , selectedCar = Nothing
    , hotelsFiltered = []
    , carsFiltered = []
    , currentProposal = Nothing
    , city = NotAsked
    , route = Route.Home
    , golfCoursesBuilder = []
    , stats = NotAsked
    , x = "createdAt"
    , y = "count"
    , emailFilter = Nothing
    , statusesFilter = []
    , email = ""
    , banner = Nothing

    -- QuoteBuilder
    , hotelName = ""
    , carName = ""
    , golfCourseStr = ""
    , modalToShow = Nothing
    , dropdownToShow = Nothing
    , toggledSection = []

    -- golfCourse
    , selectedGolfCourse = Nothing
    , golfCourse = NotAsked
    , golfCourses = NotAsked
    , golfCourseName = ""
    , golfCourseFiltered = []

    -- Quote
    , title = ""
    , rooms = Dict.empty
    , description = ""
    , bed = ""
    , refundable = ""
    , stars = ""
    , address = ""
    , selectedImages = []
    , price = ""
    , hotelPrice = ""
    , carPrice = ""
    , fee = ""
    , errors = []
    , today = millisToPosix 0
    , banners = NotAsked
    , bannerName = ""
    , bannerFile = NotAsked

    -- generator
    , generatorSetting = NotAsked
    }


main : Program Value Model Msg
main =
    Browser.application
        { init =
            \jsonValue ->
                \url ->
                    \key ->
                        let
                            config =
                                jsonValue
                                    |> De.decodeValue decodeFlags
                                    |> Result.map
                                        (\flag ->
                                            { domain = flag.domain
                                            , user = Authentication.init auth0authorize auth0logout flag.user
                                            , authentificationCallbackurl = flag.authentificationCallbackurl
                                            }
                                        )
                        in
                        case config of
                            Err e ->
                                ( defaultModel key
                                , auth0authorize Auth0.defaultOpts |> Cmd.map AuthenticationMsg
                                )

                            Ok c ->
                                let
                                    ( model, cmds ) =
                                        update (ChangedUrl url)
                                            { navigationKey = key
                                            , config = c
                                            , emailPreview = NotAsked
                                            , requestedPackages = NotAsked
                                            , requestedPackage = NotAsked
                                            , customer = NotAsked
                                            , customers = NotAsked
                                            , predictions = NotAsked
                                            , hotel = NotAsked
                                            , hotels = NotAsked
                                            , cars = NotAsked
                                            , images = NotAsked
                                            , debounce = Debounce.init
                                            , selectedHotel = Nothing
                                            , selectedCar = Nothing
                                            , hotelsFiltered = []
                                            , carsFiltered = []
                                            , currentProposal = Nothing
                                            , city = NotAsked
                                            , route = Route.Home
                                            , golfCoursesBuilder = []
                                            , stats = NotAsked
                                            , x = "createdAt"
                                            , y = "count"
                                            , emailFilter = Nothing
                                            , statusesFilter = []
                                            , email = ""
                                            , banner = Nothing

                                            -- QuoteBuilder
                                            , hotelName = ""
                                            , carName = ""
                                            , golfCourseStr = ""
                                            , modalToShow = Nothing
                                            , dropdownToShow = Nothing
                                            , toggledSection = []

                                            -- golfCourse
                                            , selectedGolfCourse = Nothing
                                            , golfCourse = NotAsked
                                            , golfCourses = NotAsked
                                            , golfCourseName = ""
                                            , golfCourseFiltered = []

                                            -- Quote
                                            , title = ""
                                            , rooms = Dict.empty
                                            , description = ""
                                            , bed = ""
                                            , refundable = ""
                                            , stars = ""
                                            , address = ""
                                            , selectedImages = []
                                            , price = ""
                                            , hotelPrice = ""
                                            , carPrice = ""
                                            , fee = ""
                                            , errors = []
                                            , today = millisToPosix 0
                                            , banners = NotAsked
                                            , bannerName = ""
                                            , bannerFile = NotAsked

                                            -- generator
                                            , generatorSetting = NotAsked
                                            }
                                in
                                ( model, Cmd.batch [ cmds, Task.perform HandleNow Time.now ] )
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        , subscriptions = subscriptions
        , update = update
        , view = view
        }
